import {toAbsoluteUrl} from '../../_metronic/helpers'

// meta tags
export const metaTags_data = {
  title: 'Monoliths',
  siteName: 'Monoliths',
  description:
    'Monoliths helps you see the potential of investing. Go ahead, explore your possibilities.',
  logo: 'frontend/public/monolith-logo.svg',
  // Vividli
  Vividli_title: 'Vividli',
  Vividli_siteName: 'Vividli',
  Vividli_description:
    'Vividli helps you see the potential of investing. Go ahead, explore your possibilities.',
}

// footer section
export const FOOTER_DATA = {
  // text
  JOIN_TEAM: 'Join the Team',
  CONTACT_US: 'CONTACT US',
  MISSION: `We're on a mission, and are seeking like-minded customers, engineers, designers, advisors, allies, supporters, and friends. Email us a note!`,
  ONYXTEC_CREDIT: 'Site built with love by',
  ONYXTEC: 'OnyxTec',
  LEGAL_NOTES_FIRST_TITLE: 'Here are some legal notes:',
  LEGAL_NOTES_SECOND_TITLE: 'Here are some more legal notes:',
  ABOUT_MONOLITHS: 'VIVIDLI_ABOUT_MONOLITHS',
  VIVIDLI_ABOUT_MONOLITHS:
    'Vividli.ai (formerly aka Monoliths.io) is run by a group of individuals in Seattle and beyond who dream about making investing easier to understand... by helping you see wealth differently. This page is for informational purposes only – it is not financial nor investment advice. We are not a financial advisor nor fiduciary. (Nor do we play one on tv.) Everyone’s situation is different, so please consult with an accountant, fiduciary, or advisor. Joining the interest list enables us to be in touch with you for feedback. Visuals used on this page are either original to Vividli.ai or permitted for open-use by Canva.com, or Figma.',

  LEGAL_NOTES_PARAGRAPH:
    "This page is a rough illustration of a product concept. It is not financial advice, nor offer for investment. Let's talk about risks. All investing has risk of loss. Historic performance is no guarantee of future returns.",
  DISCLAIMER:
    'Disclaimer: The information on this website is for general informational purposes only and does not constitute investment advice or a recommendation to buy or sell any securities or other financial products. The calculator service is an automated online tool based on your goals, risk tolerance and time horizon. The calculator service does not guarantee any specific results or returns, and past performance is not indicative of future performance. Investing involves risk, including the possible loss of principal. You should consult a qualified financial professional before making any investment decisions. The calculator service is not responsible for any errors or omissions in the information provided on this website or for any damages arising from the use or reliance on this information',
  // images
  IMAGES: {
    ONYXTEC_LOGO: toAbsoluteUrl('/media/home/logo_onyxtec.png'),
    FOOTER_BIG_IMG: toAbsoluteUrl('/media/home/footer-big-img.svg'),
    FOOTER_CLOUD_BOTTOM: toAbsoluteUrl('/media/home/footer-cloud-bottom.svg'),
    FOOTER_MOB_MOUNTAINS: toAbsoluteUrl('/media/home/footer-mob.svg'),
  },
}

// financial section
export const FINANCIAL_SECTION = {
  // text
  FINANCIALLY_FREE: 'What if every human on Earth was financially free?',
  FULL_POTENTIAL: 'Seeing wealth differently?',
  FREEDOM:
    'We design these tools for simpler financial decision making, to help you achieve your goals',
  PEACE: 'Feel the peace of mind that comes with financial security.',
  MISSION:
    'We design these tools for simpler financial decision making, to help you acheive your goals.',
  ABUNDANCE: '',
  DISCORD_LINK: toAbsoluteUrl('https://discord.gg/BZ6BC4dE4'),
  CONTACT_US_EMAIL: 'hello@monoliths.io',
  // images
  IMAGES: {
    CLOUD_WHITE: toAbsoluteUrl('/media/home/financial-cloud-white.svg'),
    FADE_CLOUD_RIGHT_SMALL: toAbsoluteUrl('/media/home/fi-fade-cloud-right-small.svg'),
    FADE_CLOUD_RIGHT_LARGE: toAbsoluteUrl('/media/home/fi-fade-cloud-big-right.svg'),
    EMAIL_ICON: toAbsoluteUrl('/media/home/email.svg'),
    REDDIT_ICON: toAbsoluteUrl('/media/home/reddit.svg'),
    DISCORD_ICON: toAbsoluteUrl('/media/home/discord.svg'),
    RED_BIRD_LEFT: toAbsoluteUrl('/media/home/red-bird.svg'),
    RED_BIRD_RIGHT: toAbsoluteUrl('/media/home/red-bird-right.svg'),
    LINE_RIGHT: toAbsoluteUrl('/media/home/lines-right.svg'),
    LINE_LEFT: toAbsoluteUrl('/media/home/lines-left.svg'),
    BIRD1: toAbsoluteUrl('/media/home/bird1.svg'),
    BIRD2: toAbsoluteUrl('/media/home/bird2.svg'),
    BIRD3: toAbsoluteUrl('/media/home/bird3.svg'),
    BIRD4: toAbsoluteUrl('/media/home/bird4.svg'),
    BIRD5: toAbsoluteUrl('/media/home/bird5.svg'),
    BIRD6: toAbsoluteUrl('/media/home/bird6.svg'),
    BIRD7: toAbsoluteUrl('/media/home/bird7.svg'),
    BIRD8: toAbsoluteUrl('/media/home/bird8.svg'),
    BIRD9: toAbsoluteUrl('/media/home/bird9.svg'),
    BIRD10: toAbsoluteUrl('/media/home/bird10.svg'),
    BIRD11: toAbsoluteUrl('/media/home/bird11.svg'),
    BIRD12: toAbsoluteUrl('/media/home/bird12.svg'),
    BIRD13: toAbsoluteUrl('/media/home/bird13.svg'),
    BIRD14: toAbsoluteUrl('/media/home/bird14.svg'),
    BIRD15: toAbsoluteUrl('/media/home/bird15.svg'),
    BIRD16: toAbsoluteUrl('/media/home/bird16.svg'),
  },
}

// stay tuned section
export const STAYTUNED_SECTION = {
  // text
  STAY_TUNED: 'Stay tuned for the full experience',
  IMAGINE: `We're building a whole new set of visual tools to help you:`,
  SAVE_TIME: 'Save Time',
  NO_NEED: 'No need to become an expert.',
  CONFIDENT: 'Be Confident',
  HARD_MATH: 'No hard math or graphs, use your intuition.',
  GET_RETURNS: 'Get Returns',
  INVESTING: 'Investing could help give extra earnings to upgrade your lifestyle.',
  JOIN_WAITLIST: 'JOIN WAITLIST',
  // images
  IMAGES: {
    CLOUD_RIGHT: toAbsoluteUrl('/media/home/stay-tuned-cloud-right.svg'),
    CLOUD_LEFT: toAbsoluteUrl('/media/home/stay-tuned-cloud-left.svg'),
    ELLIPSE_RIGHT: toAbsoluteUrl('/media/home/stay-tuned-ellipse-right.svg'),
    ELLIPSE_LEFT: toAbsoluteUrl('/media/home/stay-tuned-ellipse-left.svg'),
    PATH_RIGHT: toAbsoluteUrl('/media/home/stay-tuned-path-right.svg'),
    PATH_LEFT: toAbsoluteUrl('/media/home/stay-tuned-path-left.svg'),
  },
}

// beginners section
export const BEGINNERS_SECTION = {
  // text
  WE_DO: "Doin' this for beginners",
  WE_DO_FOR_ABOUT_PAGE: 'Simplifying investing',
  VISUALIZATION: 'We’re a visualization based advisors in Seattle, Washington.',
  OUR_MISSION:
    'Our mission is to make every human on Earth financially free, so they can realize their full potential. But investing is intimidating, especially as beginners. We hope such visual calculators help you with your journey, with confidence, one investment at a time.',
  MONOLITH_HELP:
    'Using Monoliths helps more and more people get closer to  realizing their dreams.',
  VIVIDLI_MONOLITH_HELP:
    'Using Vividli helps more and more people get closer to  realizing their dreams.',

  OUR_VALUES: 'Our Values',
  INTEREST_LIST: 'Interest list',
  // images
  IMAGES: {
    CLOUD_SMALL: toAbsoluteUrl('/media/home/beginners-cloud-small.svg'),
    CLOUD_LARGE: toAbsoluteUrl('/media/home/beginners-cloud-big.svg'),
    WATERING_PLANTS: toAbsoluteUrl('/media/home/watering-plants.svg'),
    VALUES_BAG: toAbsoluteUrl('/media/home/bags-values.svg'),
  },
}

// same math new look section
export const SAME_MATH_SECTION = {
  // text
  SAME_MATH: 'Same math, new look',
  POPULAR_INVESTMENTS: 'Pick your visuals',
  HELP: 'Help your clients see money in a new way.',
  STANDARD_FORMULA: 'Choose your models',
  FINANCIAL_FORMULA: 'You drive the financial assumptions.',
  SHOWING_RISK: 'Customize',
  PREDICT: `Need a visual or model we don't already support? Let's chat.`,

  // images
  IMAGES: {
    WATERING_CAN: toAbsoluteUrl('/media/home/watering-can.svg'),
    CALC_BIRD: toAbsoluteUrl('/media/home/calc-bird.svg'),
    MAG_GEARS: toAbsoluteUrl('/media/home/mag-gears.svg'),
  },
}

// lifestyle section
export const LIFESTYLE_SECTION = {
  // text
  LIFE_STYLE: 'Upgrade your lifestyle',
  INVESTING:
    "Investing could unlock a world of possibilities. It can give you the means to make your dreams a reality. Whether it's traveling the world, buying your dream home, or providing for your loved ones, investing empowers you to create the life you've always envisioned.",
  INTIMIDATING:
    'But, investing can be intimidating. So we are making reasoning about it easy and intuitive. Take charge of your financial future by planting your garden of investment today!',
  // images
  IMAGES: {
    BIRD_HOUSE: toAbsoluteUrl('/media/home/full-bird-house.svg'),
    CLOUD_TOP_SMALL: toAbsoluteUrl('/media/home/lifestyle-cloud-top-small.svg'),
    CLOUD_TOP: toAbsoluteUrl('/media/home/lifestyle-cloud-top.svg'),
    CLOUD_LEFT_SMALL: toAbsoluteUrl('/media/home/lifestyle-cloud-left-small.svg'),
    CLOUD_LEFT_LARGE: toAbsoluteUrl('/media/home/lifestyle-cloud-left-large.svg'),
    CLOUD_RIGHT_SMALL: toAbsoluteUrl('/media/home/lifestyle-cloud-right-small.svg'),
    CLOUD_RIGHT_LARGE: toAbsoluteUrl('/media/home/lifestyle-cloud-right-large.svg'),
  },
}
//sbloc section
export const SBLOC_SECTION = {
  // text
  HEADING: 'SBLOC Visualization',
  SUB_HEADING: 'Heard how some people "bank on themselves" indefinitely? See how.',
  Link: 'https://www.investopedia.com/terms/s/securitiesbased-lending.asp',
  DESCRIPTION:
    'One way is with a Securities-Backed Line of Credit (SBLOC). SBLOCs let you borrow against your investments. You pay a daily interest rate on the outstanding amount borrowed. You can often then re-invest this money to other (dissimilar) assets, generating even more returns. Over time, so long as your rate of returns out-pace your borrowing costs, you can end out ahead. But, with high interest rates nowadays, borrowing can eat into your earnings – and assets. Read more about SBLOCs at',
}
//credit card debt section
export const CCDSection = {
  // text
  HEADING: 'Pay off debts first',
  SUB_HEADING: 'Paying down credit card debts quickly can have a higher “ROI” than stocks.',
  Link: 'https://www.nerdwallet.com/article/credit-cards/how-is-credit-card-interest-calculated',
  DESCRIPTION:
    'Credit cards are easy to use and often offer fun perks, but they can have a painful darkside: the high interest rates they charge when you don’t pay in full at the end of the month. Worse, the interest ‘compounds’ on itself: carry a balance for more than two months, and you get charged interest on your interest ! This chart shows how quickly this can add up for typical credit cards. When you pay down a card, the “return on investment” (ROI) you get is saving yourself all that interest. If your card has high rates, this “ROI” can be more bang for your buck than putting it in stocks! Read more about credit card debts at',
  POST_CHART_DESCRIPTION:
    'This calculator is for illustrative purposes only, as an example of credit card interest. Please check your actual credit card’s terms for your actual interest rates and fees.',
}
//feature suggestion section
export const FEATURE_SUGGESTION_SECTION = {
  // text
  HEADING: 'What would you like visualized?',
  SUB_HEADING: 'Pick your top 3 to let us know!',
}

// investment section
export const INVESTMENT_SECTION = {
  // text
  INVESTMENT: 'Your investment garden starts with a click',
  POPULAR_SERVICES:
    'We feature popular services that strive to serve people who are new to investing. Click on their links below to find out more.',
  VIVIDLI_POPULAR_SERVICES:
    'We list these popular services that strive to serve people who are new to investing. Click on their links below to find out more.',
  COMMISSION_FORM: `You can also read more about investing <a target='_blank' href="https://www.investopedia.com/articles/basics/11/3-s-simple-investing.asp">here.</a> We may get a commission from them at no extra cost to you. Thank you – that will help us continue our mission to inspire people to take steps for their financial freedom.`,
  // images
  IMAGES: {
    ROCK_TREES: toAbsoluteUrl('/media/home/rocks-trees.svg'),
  },
  // services partner array
  SERVICES_PARTNERS: [
    {
      id: 1,
      image: toAbsoluteUrl('/media/home/acorns.svg'),
      link: 'https://www.acorns.com/',
    },
    {
      id: 2,
      image: toAbsoluteUrl('/media/home/betterment.svg'),
      link: 'https://www.betterment.com/',
    },
    {
      id: 3,
      image: toAbsoluteUrl('/media/home/fidelity.svg'),
      link: 'https://www.fidelity.com/',
    },
    {
      id: 4,
      image: toAbsoluteUrl('/media/home/wealth-simple.svg'),
      link: 'https://www.wealthsimple.com/en-ca',
    },
    {
      id: 5,
      image: toAbsoluteUrl('/media/home/stash.svg'),
      link: 'https://www.stash.com/',
    },
  ],
}

// hero section
export const HERO_SECTION = {
  // text
  GROWTH: 'Investing, visually',
  CALCULATOR: 'We provide visualization tools to help beginners with investing.',
  VIVIDLI_CALCULATOR: 'We make investing vivid.',
  JOIN_WAITLIST: 'Join us on the journey!',
  CALCULATOR_MOBILE: 'Monoliths helps you see the potential of investing.',
  EXPLORE: 'Explore',

  ARQ_HEADER: 'See how investing in ARQ could grow for you',
  ARQ_SUB_HEADING:
    'Salaams! See how ARQ compares. Hit Meet or Reply with any questions. - Aniq, CEO, ARQ Ventures',
  // images
  IMAGES: {
    LOGO_IMAGE: toAbsoluteUrl('/Vividli-logo.svg'),
    HERO_IMAGE: toAbsoluteUrl('/media/home/hero-image.svg'),
    TOP_CLOUD: toAbsoluteUrl('/media/home/hero-mob-top-cloud.svg'),
    BOTTOM_CLOUD: toAbsoluteUrl('/media/home/hero-mob-bottom-cloud.svg'),
    MID_CLOUD: toAbsoluteUrl('/media/home/hero-mob-mid-cloud.svg'),
    HILLS: toAbsoluteUrl('/media/home/hero-mob-hills-trees.svg'),
    MOUNTAINS: toAbsoluteUrl('/media/home/hero-mob-mountains.svg'),
    EXPLORE_ARROW: toAbsoluteUrl('/media/home/explore-arrow.svg'),
  },
}

// projections section
export const PROJECTIONS_SECTION = {
  // text
  PROJECTIONS: 'Projections',
  STARTING_AMOUNT: 'Starting Amount',
  RETURN_RATE: 'Rate of Return',
  YEARS_TO_GROW: 'Years to Grow',
}

// custom bar
export const CUSTOM_BAR = {
  // text
  TITLE: 'Add your own custom investment',
}

// coming soon section
export const FEATURE_COMING_SOON = {
  // text
  TITLE: 'Feature Coming Soon: Join Our Waitlist for Exclusive Early Access!',
  MESSAGE:
    'Thank you for your interest. We are still working on this feature. Please join our waitlist to be among the first to get this feature when ready!',
}

// input tool tip section
export const INPUT_BOX_TOOLTIPS = {
  // text
  STARTING_AMOUNT:
    'How much you want to start with? Usually, the more the better, since your money can grow more, and faster, the sooner you start investing.',
  CONTRIBUTIONS:
    'How much can you invest regularly? Think of this like savings - how much and often you save can make your savings grow faster.',
  EXTRA_CONTRIBUTIONS:
    'How much can you invest regularly? Think of this like savings - how much and often you save can make your savings grow faster. \nSame investments, like ARQ, do not take these extra contributions.',
  SET_GOAL: 'Set a goal',
  SAVE_INPUTS: 'Save inputs for later use',
  GOAL_NAME:
    'Go ahead, describe what you want to get. Now, close your eyes and imagine reaching your goal!',
  GOAL_VALUE: 'Roughly how much money would help you reach your goal?',
  ARQ_FIELDS:
    "Expand this section to make changes to ARQ's investments, such as the amount of rent it makes, value of the property, and profit from selling it.\nThe default numbers you see here are an example of a typical ARQ investment, and are set by ARQ.",
  RATE_OF_RETURN:
    'Different investments can grow at different rates. Usually, lower-risk investments like banks or high-yield cash accounts grow slower, and higher-risk ones like stocks and crypto grow faster. These rates of return can vary over time with the economy. The rates we use here are typical recently (and for stocks, historically) for these investment types. \nBut, remember, rate of returns are not guaranteed. Past performance does not guarantee future results.',
  YEARS_TO_GROW:
    "The longer you are invested, the more likely you are to see investment gains. Time lets you start small and build up. That's because your money had more time to 'work for you.' When you invest for shorter-terms, you may have to put more money in yourself, go for higher-risk investments, or both.",
  END_OF_SENTENCE_FV:
    "We used the simple, standard, 'future value' formula to project what your investment might look like. Some investments can lose value over time because of inflation or fees. (Inflation is eating cash nowadays!) While nothing is guaranteed in investing - and just because an investment did really well in the past doesn't mean it must keep doing well in the future - generally, most investments grow if you hold them over time. For custom investments, we use what the investment provided",
  BORROW_AMOUNT: 'Testing',
  RE_INVESTMENT: 'Testing',
  // images
  IMAGES: {
    TOOLTIP_ICON: toAbsoluteUrl('/media/icons/duotune/general/tooltip.svg'),
  },
  Customer_Actual:
    'Check this box if all of the phase 1 gain entered here belongs to this one customer.',
}
export const SBLOC_INPUT_BOX_TOOLTIPS = {
  // text
  STARTING_AMOUNT: 'How much the Securities backing your line of credit is worth today.',
  Asset_rate_of_return:
    'Asset rate of return - how much you estimate your the Securities backing your line of credit are growing annually.',
  YEARS_TO_GROW: 'Years to grow',
  BORROWED_AMOUNT:
    'Borrowed amount is how much of your line of credit are you using. There is typically a limit to how much you are "approved to draw," such as up to 50% of the value of your Securities',
  ANNUALIZED_INTEREST_RATE:
    'Annualized interest rate. Your cost of borrowing this money against your Securities is typically set by your lending bank, such as SOBOR + a margin. In this calculator, we assume you are letting your interest compound within your SBLOC.',
  REINVESTED_AMOUNT:
    'Re-invested amount is how much of the borrowed money you are investing in another (often non-correlated) asset (for example, rental real estate). You can get returns on this re-investment. In this simple example, your re-invested amount compounds on itself; however you could also conceptually put this amount back into your Securities too if those are giving you a higher rate of return',
  REINVESTING_RATE_OF_RETURN:
    'Re-investing rate of return is how much you expect annually your re-investing amount to grow.',
  CASH_FLOW: 'Cash Flow',
}
// contact us page
export const CONTACT_US = {
  // text
  CONTACT: 'Contact Us',
  // images
  IMAGES: {
    LOGO_IMAGE: toAbsoluteUrl('/monolith-logo.svg'),
    BACK_ICON: toAbsoluteUrl('/media/icons/duotune/arrows/arr074.svg'),
  },
}

export const ADVISORS = {
  HEADING: 'Show your customers how investing could grow for them',
  STARTING_AMOUNT:
    'How much you want to start with? Usually, the more the better, since your money can grow more, and faster, the sooner you start investing.',
  CONTRIBUTIONS:
    'How much can you invest regularly? Think of this like savings - how much and often you save can make your savings grow faster.',
  RATE_OF_RETURN:
    'Which investment do you want the customer to focus on when they first see the chart?',
  YEARS_TO_GROW:
    "The longer you are invested, the more likely you are to see investment gains. Time lets you start small and build up. That's because your money had more time to 'work for you.' When you invest for shorter-terms, you may have to put more money in yourself, go for higher-risk investments, or both.",
  CUSTOM_EMAIL: 'Enter email address',
  PROPERTY_WORTH:
    'The total value of the real estate property. This is like what you might see if you go to Redfin or Zillow, for example.',
  CONTRIBUTIONS_LABEL:
    'Dividends What cash the customer regularly get? Such as rental income, or stock dividends.',
  CUSTOM_ROR_TOOLTIP:
    'What is the RoR you advertise or target, based on your models? (Leave blank to auto-calculate.)',
  CUSTOM_INV_NAME_TOOLTIP: 'What name do your customers use to refer to this investment?',
  CUSTOM_INV_TOOLTIP: `Specify your own investment`,
  REMOVE_CUSTOM_INV_TOOLTIP: 'Remove this custom field',
  CUSTOM_ROR: 'Select custom rate of return',
  LUMP_SUM_GAINS_TOOTTIP: 'Such as profits from a sale, flip/',
  APPRECIATION_GAIN_TOOLTIP: `What is the FV of the expected appreciation for this customer's investment`,
  DIVIDENDS_TOOLTIP:
    'What cash does the customer regularly get? Such as rental income, stock dividends.',
  GREETINGS_TOOLTIP:
    "Write a message to your customer! Please don't include any private or sensitive information here though.",
  NOTES_TOOLTIP: 'Jot something down',
  LOGO_TOOLTIP: "This let's you brand the page. Pick a high-res logo as .jpg, or .svg",
  POWERED_BY_MESSAGE: 'Powered by Monoliths Visualization Service',
  VIVIDLI_POWERED_BY_MESSAGE: `Powered by Vividli's Monoliths visualization service`,
}

export const PROJECTIONS_TABLE = {
  TITLE: 'Here are the numbers',
  TIME_YEAR: 'Year',
  TIME_MONTH: 'Month',
  INITIAL_DEPOSIT: 'Initial Deposit',
  CONTRIBUTIONS: 'Contribution',
  TOTAL_CONTRB: 'Total Contribution',
  GAINS: 'Gain',
  TOTAL_GAINS: 'Total Gain',
  TOTAL: 'Estimated Total',
  CUSTOM_ROR: 'Annualized RoR',
  FUTURE_VALUE: 'Total Value',
  PROFIT_PHASE_1: 'Profit from Exit',
  RENTAL_INCOME: 'Rental Income',
  ROI: 'ROI',
  CASH_ON_CASH: 'Cash on Cash',
  TOTAL_ROI: 'Total ROI',
  TOTAL_CASH_ON_CASH: 'Total Cash on Cash',
}
export const SBLOC_TABLE = {
  TITLE: 'Here are the numbers',
  TIME_YEAR: 'Year',
  INITIAL_DEPOSIT: 'Initial Deposit',
  ROR: 'RoR',
  CUMMULATIVE_RETURNS: 'Cumulative returns',
  BORROWED_AMOUNT: ' Borrowed Amount',
  APR: 'APR',
  CUMMULATIVE_DEBT: 'Cumulative debt',
  REINVESTMENT: 'Reinvestment',
  REINVESTMENT_RoR: 'Reinvestment RoR',
  CUMMULATIVE_REINVESTMENT: ' Cumulative reinvestment',
  CASH_FLOW: 'Reinvest cashflow',
  TOTAL_CASH: 'Total Cash',
}
export const TSBG = {
  title: `Here's the chart`,
}

export const BOTTOMLINE = {
  title: `The Bottom Line`,
}

export const PAST_PERFORMANCE = {
  title: 'How ARQ Performed',
}

export const TYPES_OF_ROR = {
  TYPE_SIMPLE: 'Simple RoR',
  TYPE_ARQ_FLIP: 'ARQ Fix & Flip (Legacy)',
  TYPE_ARQ_RENTAL: 'ARQ Rental (Legacy)',
  TYPE_BEFORE_EXIT: 'ARQ Before Exit',
  TYPE_AFTER_EXIT: 'ARQ After Exit',
  TYPE_RENTAL_INCOME_ONLY:'Rental Income Only',
  TYPE_RENTAL_SALE:'Rental & Sale'
}

export const PICTOGRAPH_SECTION = {
  MAX_LIMIT: 40,
  IMAGES: {
    TRIANGLE: toAbsoluteUrl('/media/home/triangle-svgrepo-com.svg'),
    CIRCLE: toAbsoluteUrl('/media/home/circle-svgrepo-com.svg'),
    PEN: toAbsoluteUrl('/media/home/square-pen-svgrepo-com.svg'),
  },
}

// feedback section
export const FEEDBACK_SECTION = {
  IMAGES: {
    LIKE_OUTLINE: toAbsoluteUrl('/media/home/like-outline.svg'),
    LIKE_FILLED: toAbsoluteUrl('/media/home/like-filled.svg'),
    DISLIKE_OUTLINE: toAbsoluteUrl('/media/home/dislike-outline.svg'),
    DISLIKE_FILLED: toAbsoluteUrl('/media/home/dislike-filled.svg'),
  },
}

export const MY_PERFORMANCE_SECTION = {
  TITLE: 'How your ARQ performed',
  LAST_QUARTER: 'Last Quarter',
  YEAR_TO_DATE: 'Year to date',
  LIFE_TIME_TO_DATE: 'Lifetime returns',
  Projections_TITLE: 'Projections',
  NEXT_2_YEARS: 'For next 2 years',
}

export const sectionIds = [
  'projectionsInput&Table', //0
  'tsbg-chart', //1
  'projections-table', //2
  'pictograph', //3
  'upgrade-lifestyle', //4
  'same-math-new-look', //5
  'sbloc-section', //6
  'beginner-section', //7
  'stay-tuned-section', //8
  'feature-suggestion', //9
  'financial-freedom', //10
  'join-team', //11
  'parallax-section', //12
  'footer', //13
  'bottom-line', //14
  'credit-card-dept-section', //15
  'join-interest-list', //16
  'past-performance', //17
  'compare-actual', //18
  'hard-concepts' //19
]

export const AdvisorsMeetLinks = {
  AGHAZ_LINK: 'https://calendly.com/khurram-agha',
  ARQ_LINK: 'https://calendly.com/aniqtanwir/30min',
}

export const ABOUT_PAGE = {
  FIND_OUT_MORE: 'Find out more',
  BE_ANGEL_DESC: 'Scale your plans to help millions of everyday people achieve financial freedom.',
  GIVE_IT_A_TRY: 'Give it a try',
  GIVE_IT_A_TRY_DESC: 'Chat with us to take our AI for a spin.',
  SUPPORT: 'Support',
  SUPPORT_DESC: "We're seeking to connect with like-minded CFPs and allies to advance the cause.",
  STAY_TUNED: 'Stay tuned',
  STAY_TUNED_DESC: "Add yourselves to our interest list to hear updates about Vividli's AI.",
  ABOUT_HERO_SECTION: {
    LEARN_MORE: 'Learn more',
    HEADING: 'Just-in-time AI infographics',
    SUB_HEADING: 'CFPs, show clients your complex financials as simple, vivid visuals using AI.',
    JOIN: 'Join us on the journey!',
  },
  REASON_SECTION: {
    RE_DEFINING: 'We are re-defining how humans reason about money',
    ACCESSIBLE: 'Vivid visuals make finance accessible to everyone. Join us!',
    BEFORE_AFTER: `Customers, user Vividli AI to see hard investing decisions simply as: "before, or after?" No more abstract math, no more headaches.`,
    BUSINESSES:
      "Businesses, use Vividli AI to clearly show how your investments perform. Save those precious hours you're spending in Excel.",
    EVERYONE:
      "Everyone, use Vividli AI to see how your 'garden of investment' (and balance!) can bloom. Satisfy customers, stay invested, grow together.",
  },
  INTEREST_LIST: {
    JOIN_INTEREST_LIST: 'JOIN INTEREST LIST',
  },
  BEGINNERS_SECTION: {
    VISUALIZATION: 'We’re a decision-making visualization startup based in Seattle, Washington.',
    OUR_MISSION:
      "Our mission is to make every human on Earth financially free, so they can realize their full potential. But investing is intimidating when you don't speak its jargon. So we make finance intuitive by using AI to transform complex plans into simple, vivid visuals.",
    SECOND_MISSION: 'Use our AI to help your clients see their wealth in a new way.',
  },
}
// navbar
export const NAVBAR_CONSTANTS = {
  NAVLINK1: 'Home',
  NAVLINK2: 'Arq Home',
  NAVLINK3: 'About Us',
  NAVLINK4: 'Sign Up',
  BUTTON: 'Request for free',
  // images
  IMAGES: {
    LOGO: toAbsoluteUrl('/Vividli-logo.svg'),
  },
}

export const COMPARE_PERFORMANCE_SECTION = {
  HEADING: 'See how your investment compared',
  SUB_HEADING:
    'Compare your investment to how other common ones performed over the same time period.',
  MODAL_TITLE: 'Add your own custom investment',
  TOOLTIP: {
    FIELD1: 'Start date',
    FIELD2: 'Start value',
    FIELD3: 'End date',
    FIELD4: 'End value',
    FIELD5: 'Investments to Compare',
    FIELD6: 'Custom Name',
  },
}

export const PROJECTION_INPUTS_SECTION = {
  HEADING: 'Comparison tool',
  MESSAGE: `Wondering what to invest in? Use this tool to visualize what investing in some popular asset classes could look like. They're arranged by how fast they grow. But faster growth can also come with higher risk.`,
  WAYSTONE_MESSAGE: `See how investing in Waystone's Assetmark LargeCap can compare to other popular assets.`,
}
