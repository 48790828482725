import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {UserLayout} from '../../_metronic/layout/UserLayout'
import {HomePage} from '../user/pages/home/HomePage'
import {ARQPage} from '../user/pages/arq/ARQPage'
import {sectionIds} from '../constants/AppConstants'
import {MyPerformancePage} from '../user/pages/myPerformance/MyPerformancePage'

const UserRoutes = () => {
  const AboutUs = lazy(() => import('../user/pages/about/AboutPage'))
  const ContactUs = lazy(() => import('../user/modules/contact/ContactPage'))
  //   const ProfilePage = lazy(() => import('../user/modules/profile/ProfilePage'))
  //   const TOCPage = lazy(() => import('../user/modules/TOC/TOCPage'))
  const AdvisorsPage = lazy(() => import('../user/modules/advisor/AdvisorsPage'))

  useEffect(() => {
    const handleScroll = () => {
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top <= 50 && rect.bottom >= 50) {
            window.history.replaceState({}, '', `#${id}`)
            break // Break the loop when the first active section is found
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Routes>
      <Route element={<UserLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />

        {/* Pages */}
        <Route path='home' element={<HomePage />} />

        <Route
          path='about'
          element={
            <SuspensedView>
              <AboutUs />
            </SuspensedView>
          }
        />
        <Route
          path='contact'
          element={
            <SuspensedView>
              <ContactUs />
            </SuspensedView>
          }
        />
        <Route
          path='contact/:advisor_url'
          element={
            <SuspensedView>
              <ContactUs />
            </SuspensedView>
          }
        />

        <Route
          path='/arq/create'
          element={
            <SuspensedView>
              <AdvisorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/realestate/create'
          element={
            <SuspensedView>
              <AdvisorsPage />
            </SuspensedView>
          }
        />
        {/* demo pages */}
        <Route
          path='advisor-demo1/create'
          element={
            <SuspensedView>
              <AdvisorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='advisor-demo2/create'
          element={
            <SuspensedView>
              <AdvisorsPage />
            </SuspensedView>
          }
        />
        <Route
          path='waystone/create'
          element={
            <SuspensedView>
              <AdvisorsPage />
            </SuspensedView>
          }
        />
        {/* demo pages */}
        <Route
          path='/arq/create/:receiver_url'
          element={
            <SuspensedView>
              <AdvisorsPage />
            </SuspensedView>
          }
        />

        <Route
          path='arq/home'
          element={
            <SuspensedView>
              <ARQPage />
            </SuspensedView>
          }
        />
        <Route
          path='arq/home/myPerformance'
          element={
            <SuspensedView>
              <MyPerformancePage />
            </SuspensedView>
          }
        />
        {/* demo pages */}
        <Route
          path='advisor-demo1/home'
          element={
            <SuspensedView>
              <ARQPage />
            </SuspensedView>
          }
        />
        <Route
          path='advisor-demo2/home'
          element={
            <SuspensedView>
              <ARQPage />
            </SuspensedView>
          }
        />
        <Route
          path='waystone/home'
          element={
            <SuspensedView>
              <ARQPage />
            </SuspensedView>
          }
        />
        {/* demo pages */}
        <Route
          path='arq/:advisor_url'
          element={
            <SuspensedView>
              <ARQPage />
            </SuspensedView>
          }
        />
        {/* demo pages */}
        <Route
          path='advisor-demo1/:advisor_url'
          element={
            <SuspensedView>
              <ARQPage />
            </SuspensedView>
          }
        />
        <Route
          path='advisor-demo2/:advisor_url'
          element={
            <SuspensedView>
              <ARQPage />
            </SuspensedView>
          }
        />
        <Route
          path='waystone/:advisor_url'
          element={
            <SuspensedView>
              <ARQPage />
            </SuspensedView>
          }
        />
        {/* demo pages */}

        {/* Lazy Modules */}
        {/* <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='about'
          element={
            <SuspensedView>
              <AboutUs />
            </SuspensedView>
          }
        />

        <Route
          path='terms'
          element={
            <SuspensedView>
              <TOCPage />
            </SuspensedView>
          }
        /> */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {UserRoutes}
