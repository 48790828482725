import React, {useRef, useState, useEffect} from 'react'
import {Autocomplete} from '@react-google-maps/api'

const AutoCompleteField = ({placeholder, onPlaceSelected, defaultAddress}) => {
  const autocompleteRef = useRef(null)
  const [address, setAddress] = useState(defaultAddress || '')

  useEffect(() => {
    if (defaultAddress) {
      setAddress(defaultAddress)
    }
  }, [defaultAddress])

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace()
      const formattedAddress = place.formatted_address

      if (formattedAddress) {
        setAddress(formattedAddress)
        onPlaceSelected({
          lat: place.geometry.location.lat() || 40,
          lng: place.geometry.location.lng() || 73,
          address: formattedAddress,
        })
      }
    }
  }

  const handleInputChange = (e) => {
    setAddress(e.target.value)
  }

  return (
    <Autocomplete
      onLoad={(ref) => (autocompleteRef.current = ref)}
      onPlaceChanged={handlePlaceChanged}
    >
      <input
        type='text'
        placeholder={placeholder}
        value={address}
        onChange={handleInputChange}
        className='form-control'
        style={{
          padding: '10px',
          borderRadius: '8px',
          border: '1px solid #ddd',
          marginBottom: '12px',
          width: '100%',
          boxSizing: 'border-box',
          color: 'black',
        }}
      />
    </Autocomplete>
  )
}

export default AutoCompleteField
