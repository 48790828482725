import React, {useState} from 'react'
import {SuggestedFeatureModal} from './_model'
import {SuggestedFeatures} from './FeatureConfig'
import {addSuggestedFeature} from '../../advisor/core/_request'
import {toast} from 'react-toastify'
import {
  FEATURE_SUGGESTION_SECTION,
  STAYTUNED_SECTION,
  sectionIds,
} from '../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import useScreenWidth from '../../hooks/useScreenWidth'
import InterestListButton from '../../../pages/about/components/InterestListButton'
const FeatureSuggestionSection: React.FC = () => {
  const screenWidth = useScreenWidth()

  const [selectedFeatures, setSelectedFeatures] = useState<SuggestedFeatureModal[]>([])
  const notifySuggestion = (msg: string) => toast.success(msg)
  const selectFeature = (feature: SuggestedFeatureModal) => {
    if (selectedFeatures.length >= 3) {
      // If the length is already 3, remove the first selected feature
      setSelectedFeatures((prevSelectedFeatures) => prevSelectedFeatures.slice(1))
    }

    // Add the new feature to the selectedFeatures
    setSelectedFeatures((prevSelectedFeatures) => [...prevSelectedFeatures, feature])
  }
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()

  const onSubmit = () => {
    for (let i = 0; i < selectedFeatures.length; i++) {
      addSuggestedFeature(selectedFeatures[i].id, selectedFeatures[i].name, 'empty')
    }
    notifySuggestion('Thank you for your suggestion!')
    setTimeout(() => {
      setSelectedFeatures([])
    }, 2000)
  }
  return (
    <div className='d-flex flex-column'>
      <section className='position-relative d-flex justify-content-center align-items-center overflow-hidden'>
        <div
          id={sectionIds[9]}
          className={`text-center position-relative z-index-2 ${
            screenWidth > 768 ? 'w-50' : 'w-90'
          } mt-20 ${
            sectionIdToShowInWebViewer !== sectionIds[9] && showInWebViewer ? 'd-none' : ''
          }`}
        >
          <h2>{FEATURE_SUGGESTION_SECTION.HEADING}</h2>
          <p className='text-center'>{FEATURE_SUGGESTION_SECTION.SUB_HEADING}</p>
          <div className='feature-container d-flex flex-wrap p-5 justify-content-center'>
            {SuggestedFeatures.map((item, index) => (
              <div
                key={index}
                className={`suggested-feature ${
                  selectedFeatures.includes(item) ? 'selected' : ''
                }  text-center`}
                onClick={() => selectFeature(item)}
              >
                {item.name}{' '}
                {selectedFeatures.includes(item) && (
                  <span className='green-tick text-center'>✓</span>
                )}
              </div>
            ))}
          </div>
          <button
            className='custom-secondary-button mt-10'
            disabled={!selectedFeatures.length}
            onClick={onSubmit}
          >
            Submit
          </button>
        </div>
        {/* clouds */}
        <img
          alt='feature-suggestion-cloud-right'
          className='feature-suggestion-cloud-right position-absolute z-index-1'
          src={STAYTUNED_SECTION.IMAGES.CLOUD_RIGHT}
        />
        <img
          alt='feature-suggestion-cloud-left'
          className='feature-suggestion-cloud-left position-absolute z-index-1'
          src={STAYTUNED_SECTION.IMAGES.CLOUD_LEFT}
        />
      </section>
      {window.location.pathname === '/about' && <InterestListButton />}
    </div>
  )
}

export default FeatureSuggestionSection
