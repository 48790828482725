import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {
  CompoundingGrowth,
  MyPerformanceAssetsCompoundingGrowth,
  PastPerformanceCompoundingGrowth,
  ComparePerformanceAssetsCompoundingGrowth,
} from '../../../helper/calculator/_model'

export const defaultAsset: number = 2

// projections assets
export const assets: CompoundingGrowth[] = [
  {
    id: 0,
    label: 'Cash under Mattress',
    dropdownLabel: '(-2.4% ) Cash under Mattress',
    customLabel: '-2.4%',
    color: '#B28F22', //color of label
    value: -2.4,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 1,
    label: 'Cash in Bank Savings',
    dropdownLabel: '(0.06%) Cash in Bank Savings',
    customLabel: '0.06%',
    color: '#85AC52',
    value: 0.06,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 2,
    label: 'High Yield Savings',
    dropdownLabel: '(4.5%) High Yield Savings',
    customLabel: '4.5%',
    color: '#85AC52',
    value: 4.5,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 3,
    label: 'Stocks (S&P)',
    dropdownLabel: '(7%) Stocks (S&P)',
    customLabel: '7%',
    color: '#DB0C00',
    value: 7,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 4,
    label: 'Crypto*',
    dropdownLabel: '(0-20%) Crypto',
    customLabel: '0-20%+',
    color: '#A40700',
    value: 20,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
  {
    id: 5,
    label: 'ARQ Before Exit',
    dropdownLabel: 'ARQ Before Exit',
    customLabel: '14%',
    color: '#9A258E',
    value: 14,
    type: TYPES_OF_ROR.TYPE_BEFORE_EXIT,
  },
  {
    id: 6,
    label: 'ARQ After Exit',
    dropdownLabel: 'ARQ After Exit',
    customLabel: '10%',
    color: '#9A258E',
    value: 10,
    type: TYPES_OF_ROR.TYPE_AFTER_EXIT,
  },
  {
    id: 7,
    label: 'Custom',
    dropdownLabel: null,
    customLabel: '',
    color: '#9A258E',
    value: 0.0000001,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
  },
]

// projections assets
export const myPerformanceAssets: MyPerformanceAssetsCompoundingGrowth[] = [
  {
    id: 0,
    label: 'Cash under Mattress',
    dropdownLabel: '(-2.4% ) Cash under Mattress',
    customLabel: '-2.4%',
    color: '#B28F22', //color of label
    value: -2.4,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
  },
  {
    id: 1,
    label: 'Cash in Bank Savings',
    dropdownLabel: '(0.06%) Cash in Bank Savings',
    customLabel: '0.06%',
    color: '#85AC52',
    value: 0.06,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
  },
  {
    id: 2,
    label: 'High Yield Savings',
    dropdownLabel: '(4.5%) High Yield Savings',
    customLabel: '4.5%',
    color: '#85AC52',
    value: 4.5,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
  },
  {
    id: 3,
    label: 'Stocks (S&P)',
    dropdownLabel: '(7%) Stocks (S&P)',
    customLabel: '7%',
    color: '#DB0C00',
    value: 6,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
  },
  {
    id: 4,
    label: 'Ethereum',
    dropdownLabel: '(20%) Ethereum',
    customLabel: '20%*',
    color: '#A40700',
    value: 20,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
  },
  {
    id: 5,
    label: 'ARQ Before Exit',
    dropdownLabel: 'ARQ Before Exit',
    customLabel: '14%',
    color: '#9A258E',
    value: 14,
    type: TYPES_OF_ROR.TYPE_BEFORE_EXIT,
    assetChartType: 'projections',
    annualizedROR: 0,
  },
  {
    id: 6,
    label: 'ARQ After Exit',
    dropdownLabel: 'ARQ After Exit',
    customLabel: '10%',
    color: '#9A258E',
    value: 10,
    type: TYPES_OF_ROR.TYPE_AFTER_EXIT,
    assetChartType: 'projections',
    annualizedROR: 0,
  },
  {
    id: 7,
    label: 'Custom',
    dropdownLabel: null,
    customLabel: '',
    color: '#9A258E',
    value: 0.0000001,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
  },
]

// compare performance assets
export const comparePerformanceAssets: ComparePerformanceAssetsCompoundingGrowth[] = []

// past performance assets
export const pastPerformanceAssets: PastPerformanceCompoundingGrowth[] = [
  {
    id: 0,
    label: 'Cash under Mattress',
    dropdownLabel: '(-2.4% ) Cash under Mattress',
    customLabel: '-2.4%',
    color: '#B28F22', //color of label
    value: -2.4,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 1,
    label: 'Cash in Bank Savings',
    dropdownLabel: '(0.06%) Cash in Bank Savings',
    customLabel: '0.06%',
    color: '#85AC52',
    value: 0.06,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 2,
    label: 'High Yield Savings',
    dropdownLabel: '(4.5%) High Yield Savings',
    customLabel: '4.5%',
    color: '#85AC52',
    value: 4.5,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 3,
    label: 'Stocks (S&P)',
    dropdownLabel: '(7%) Stocks (S&P)',
    customLabel: '7%',
    color: '#DB0C00',
    value: 6,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
    assetSymbol: 'VOO',
  },
  {
    id: 4,
    label: 'Ethereum',
    dropdownLabel: '(20%) Ethereum',
    customLabel: '20%*',
    color: '#A40700',
    value: 20,
    type: TYPES_OF_ROR.TYPE_SIMPLE,
    assetChartType: 'projections',
    annualizedROR: 0,
    assetSymbol: 'ETH',
  },
  {
    id: 5,
    label: 'ARQ Before Exit',
    dropdownLabel: 'ARQ Before Exit',
    customLabel: '14%',
    color: '#9A258E',
    value: 14,
    type: TYPES_OF_ROR.TYPE_BEFORE_EXIT,
    assetChartType: 'projections',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 6,
    label: 'ARQ After Exit',
    dropdownLabel: 'ARQ After Exit',
    customLabel: '10%',
    color: '#9A258E',
    value: 10,
    type: TYPES_OF_ROR.TYPE_AFTER_EXIT,
    assetChartType: 'projections',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 7,
    label: 'NE Eugene CT',
    dropdownLabel: 'NE Eugene CT',
    customLabel: '-2.6%',
    color: '#B28F22', //color of label
    value: -2.6,
    type: TYPES_OF_ROR.TYPE_AFTER_EXIT,
    assetChartType: 'past',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 8,
    label: 'Lake Oswego',
    dropdownLabel: 'Lake Oswego',
    customLabel: '0.06%',
    color: '#85AC52',
    value: 0.06,
    type: TYPES_OF_ROR.TYPE_BEFORE_EXIT,
    assetChartType: 'past',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 9,
    label: 'Ash St',
    dropdownLabel: 'Ash St',
    customLabel: '4.5%',
    color: '#85AC52',
    value: 4.5,
    type: TYPES_OF_ROR.TYPE_AFTER_EXIT,
    assetChartType: 'past',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 10,
    label: 'SE 137th Ave',
    dropdownLabel: 'SE 137th Ave',
    customLabel: '6%',
    color: '#DB0C00',
    value: 6,
    type: TYPES_OF_ROR.TYPE_BEFORE_EXIT,
    assetChartType: 'past',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 11,
    label: '13932 NE 60th St',
    dropdownLabel: '13932 NE 60th St',
    customLabel: '20%*',
    color: '#A40700',
    value: 20,
    type: TYPES_OF_ROR.TYPE_AFTER_EXIT,
    assetChartType: 'past',
    annualizedROR: 0,

    assetSymbol: '',
  },
  {
    id: 12,
    label: '902 W 68 St',
    dropdownLabel: '902 W 68 St',
    customLabel: '14%',
    color: '#9A258E',
    value: 14,
    type: TYPES_OF_ROR.TYPE_BEFORE_EXIT,
    assetChartType: 'past',
    annualizedROR: 0,
    assetSymbol: '',
  },
  {
    id: 13,
    label: '4852 SE 112th St',
    dropdownLabel: '4852 SE 112th St',
    customLabel: '8.18%',
    color: '#9A258E',
    value: 8.18,
    type: TYPES_OF_ROR.TYPE_AFTER_EXIT,
    assetChartType: 'past',
    annualizedROR: 0,
    assetSymbol: '',
  },
]

// contributions frequency
export const contributionAndDividendsFrequency = [
  {
    id: 1,
    label: 'Weekly',
    value: 52,
  },
  {
    id: 2,
    label: 'Bi-Monthly',
    value: 24,
  },
  {
    id: 3,
    label: 'Monthly',
    value: 12,
  },
  {
    id: 4,
    label: 'Quarterly',
    value: 4,
  },
  {
    id: 5,
    label: 'Semi-Annually',
    value: 2,
  },
  {
    id: 6,
    label: 'Yearly',
    value: 1,
  },
]

// projections colors
export const projectionsColors = {
  loss: '#ff318c',
  gain: '#feb7ff',
  contributions: '#d80ed8',
  initialDeposit: '#7c1e72',
  white: '#ffffff',
  expected: '#C0C0C0',
  borrow: '#f0568d',
  gainOnReInvestment: '#db69d7',
}

// RoR types
export const typesOfRoR = [
  {id: 0, type: TYPES_OF_ROR.TYPE_SIMPLE},
  {id: 1, type: TYPES_OF_ROR.TYPE_ARQ_FLIP},
  {id: 2, type: TYPES_OF_ROR.TYPE_ARQ_RENTAL},
  {id: 3, type: TYPES_OF_ROR.TYPE_BEFORE_EXIT},
  {id: 4, type: TYPES_OF_ROR.TYPE_AFTER_EXIT},
]

// risk charts
export const riskChart = [
  {
    category: 'Very Low Risk',
    color: '#B28F22',
    items: ['Cash under Mattress'],
  },
  {
    category: 'Low Risk',
    color: '#85AC52',
    items: ['Cash in Bank Savings', 'High Yield Savings'],
  },
  {
    category: 'Medium Risk',
    color: '#DB0C00',
    items: ['Stocks (S&P)'],
  },
  {
    category: 'High Risk',
    color: '#8b0000',
    items: ['Crypto*'],
  },
]

//Function to check each bar lies in which category and update their colors accordingly
export function getCategoryForText(text: string) {
  for (const categoryData of riskChart) {
    if (categoryData.items.includes(text)) {
      return categoryData.color
    }
  }
  // If the text is not found in any category, return a default category.
  return '#7c1e72'
}
export const StressTestConfigs = [
  {
    id: 1,
    label: 'Given',
    value: 'Given',
    editable: false,
    toParse: false,
  },
  {
    id: 2,
    label: 'Steadily Increase',
    value: 'Steadily Increase',
    editable: false,
    toParse: false,
  },
  {
    id: 3,
    label: 'LIBOR Historic Reverse (Avg Monthly Year Close)',
    value:
      '[ "0.16%", "1.76%", "2.52%", "1.56%", "0.77%", "0.43%", "0.17%", "0.17%","0.21%", "0.30%", "0.26%", "0.23%", "0.44%", "4.60%", "5.32%", "4.39%","2.40%", "1.12%", "1.38%", "1.87%", "6.56%", "5.83%", "5.06%", "5.72%","5.50%", "5.69%", "6.00%", "3.25%", "3.31%", "4.31%", "7.63%", "8.50%","9.19%", "7.13%"]',
    editable: false,
    toParse: true,
  },
  {
    id: 4,
    label: 'LIBOR Historic Reverse (Avg Monthly Year Close) +2',
    value:
      '[ "2.16%", "3.76%", "4.52%", "3.56%", "2.77%", "2.43%", "2.17%", "2.17%", "2.21%", "2.30%", "2.26%", "2.23%", "2.44%", "6.60%", "7.32%", "6.39%", "4.40%", "3.12%", "3.38%", "3.87%", "8.56%", "7.83%", "7.06%", "7.72%", "7.50%", "7.69%", "8.00%", "5.25%", "5.31%", "6.31%", "9.63%","10.50%","11.19%","9.13%"]',
    editable: false,
    toParse: true,
  },
  {
    id: 5,
    label: 'LIBOR Historic (Avg Monthly Year Close) +2',
    value:
      '["9.10","11.20","10.50","9.60","6.30","5.30","5.30","8.00","7.70","7.50","7.70","7.10","7.80","8.60","3.90","3.40","3.10","4.40","6.40","7.30","6.60","2.40","2.20","2.30","2.30","2.20","2.20","2.20","2.40","2.80","3.60","4.50","3.80","2.20"]',
    editable: false,
    toParse: true,
  },
  {
    id: 6,
    label: 'LIBOR Historic (Avg Monthly Year Close)',
    value:
      '[ "7.13%", "9.19%", "8.50%", "7.63%", "4.31%", "3.31%", "3.25%", "6.00%","5.69%", "5.50%", "5.72%", "5.06%", "5.83%", "6.56%", "1.87%", "1.38%","1.12%", "2.40%", "4.39%", "5.32%", "4.60%", "0.44%", "0.23%", "0.26%","0.30%", "0.21%", "0.17%", "0.17%", "0.43%", "0.77%", "1.56%", "2.52%","1.76%", "0.16%"]',
    editable: false,
    toParse: true,
  },
  {
    id: 7,
    label: 'GPT Prediction 1 - Soft Landing +2',
    value:
      '["7.00%", "6.90%", "6.80%", "6.70%", "6.60%", "6.50%", "6.40%", "6.30%","6.20%", "6.10%", "6.00%", "5.90%", "5.80%", "5.70%", "5.60%", "5.50%","5.40%", "5.30%", "5.20%", "5.10%", "5.00%", "4.90%", "4.80%", "4.70%","4.60%", "4.50%", "4.40%", "4.30%", "4.20%", "4.10%", "4.00%", "6.00%","6.00%", "6.00%"]',
    editable: false,
    toParse: true,
  },
  {
    id: 8,
    label: 'GPT Prediction 2 - Hard Landing +2',
    value:
      '["6.50%", "6.00%", "5.50%", "5.00%", "4.50%", "4.00%", "3.90%", "3.80%","3.70%", "3.60%", "3.50%", "3.40%", "3.30%", "3.20%", "3.10%", "3.00%","2.90%", "2.80%", "2.70%", "2.60%", "2.50%", "2.45%", "2.40%", "2.35%","2.30%", "2.25%", "2.20%", "2.15%", "2.10%", "2.05%", "2.00%", "4.00%","4.00%", "4.00%"]',
    editable: false,
    toParse: true,
  },
  {
    id: 9,
    label: 'GPT Prediction 3 - Happy Path +2',
    value:
      '["6.00%", "5.50%", "5.00%", "4.50%", "4.00%", "3.50%", "3.45%", "3.40%","3.35%", "3.30%", "3.25%", "3.20%", "3.15%", "3.10%", "3.05%", "3.00%","2.95%", "2.90%", "2.85%", "2.80%", "2.75%", "2.70%", "2.65%", "2.60%","2.55%", "2.50%", "2.45%", "2.40%", "2.35%", "2.30%", "2.25%", "4.25%","4.25%", "4.25%"]',
    editable: false,
    toParse: true,
  },
  {
    id: 10,
    label: 'GPT Prediction 4 - Pain for longer +2',
    value:
      '[ "7.50%", "7.40%", "7.30%", "7.20%", "7.10%", "7.00%", "6.90%", "6.80%","6.70%", "6.60%", "6.50%", "6.40%", "6.30%", "6.20%", "6.10%", "6.00%","5.90%", "5.80%", "5.70%", "5.60%", "5.50%", "5.40%", "5.30%", "5.20%","5.10%", "5.00%", "4.90%", "4.80%", "4.70%", "4.60%", "4.50%", "6.50%","6.50%", "6.50%"]',
    editable: false,
    toParse: true,
  },
  {
    id: 11,
    label: "GPT's Prediction",
    value:
      '["7.25%", "7.15%", "7.05%", "6.95%", "6.85%", "6.75%", "6.65%", "6.55%","6.45%", "6.35%", "6.25%", "6.15%", "6.05%", "5.95%", "5.85%", "5.75%","5.65%", "5.55%", "5.45%", "5.35%", "5.25%", "5.15%", "5.05%", "4.95%", "4.85%", "4.75%", "4.65%", "4.55%", "4.45%", "4.35%", "4.25%", "6.25%","6.25%", "6.25%"]',
    editable: false,
    toParse: true,
  },
  {
    id: 12,
    label: 'Custom 1',
    value: 'Custom 1',
    editable: true,
    toParse: false,
  },
  {
    id: 13,
    label: 'Custom 2',
    value: 'Custom 2',
    editable: true,
    toParse: false,
  },
]

export const defaultPersonasData = [
  {
    id: 0,
    value: 0,
    label: 'Select persona',
    annualized_interest_rate: 8,
    asset_rate_of_return: 9,
    borrowed_amount: 2000000,
    re_invest_rate_of_return: 10,
    re_invested_amount: 1000000,
    starting_amount: 4000000,
    years_to_grow: 15,
    cash_flow_that_year: 0,
    cash_flow_frequency: 0,
  },
  {
    id: 1,
    value: 1,
    label: 'Tech Exec',
    annualized_interest_rate: 8,
    asset_rate_of_return: 9,
    borrowed_amount: 2000000,
    re_invest_rate_of_return: 10,
    re_invested_amount: 1000000,
    starting_amount: 4000000,
    years_to_grow: 15,
    cash_flow_that_year: 0,
    cash_flow_frequency: 52,
  },
  {
    id: 2,
    value: 2,
    label: 'Entry Client',
    annualized_interest_rate: 8,
    asset_rate_of_return: 10,
    borrowed_amount: 50000,
    re_invest_rate_of_return: 5,
    re_invested_amount: 50000,
    starting_amount: 100000,
    years_to_grow: 11,
    cash_flow_that_year: 1000,
    cash_flow_frequency: 4,
  },
  {
    id: 3,
    value: 3,
    label: 'Persona3 - Growth Only',
    annualized_interest_rate: 8,
    asset_rate_of_return: 10,
    borrowed_amount: 0,
    re_invest_rate_of_return: 5,
    re_invested_amount: 0,
    starting_amount: 100000,
    years_to_grow: 10,
    cash_flow_that_year: 0,
    cash_flow_frequency: 4,
  },
]