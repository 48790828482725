import React, {useState, useEffect, useRef} from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Text,
  ReferenceLine,
  LabelList,
  ReferenceArea,
} from 'recharts'
import {useParams, useLocation} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import {downloadChart, toAbbreviatedNumber} from '../../../helper/charts.helper'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {projectionsColors} from './ProjectionsConfig'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import ProjectionChartTooltip from './ProjectionChartTooltip'
import {InvestmentChartProps} from '../../../helper/calculator/_model'
import {getCategoryForText} from './ProjectionsConfig'
import MyPerformanceTooltip from '../../myPerformance/components/MyPerformanceTooltip'

let customBarLabel = ''
let createCustomBarLabel = function (data: any) {
  customBarLabel = data[data.length - 1]?.label
  return customBarLabel
}
const ProjectionsChart: React.FC<any> = (props) => {
  const {
    receiverFormDataObj,
    goalSettingBar,
    setPreviousData,
    isReceiverDataLoading,
    isProjectionsInputChanged,
    setSelectedBar,
  } = useReceiverFormContext()
  const {data, handleCustomBar, handleBarRateOfreturn} = props
  const [currentData, setCurrentData] = useState<InvestmentChartProps[]>([])
  const previousDataRef = useRef<InvestmentChartProps[]>([])
  const previousPropsRef = useRef<any | null>(null)
  let propsArray: any = []
  // const {tableData, chartData, isDataSet, handleReplyButton, setTableData, setChartData} =
  //   useProjectionsState()
  useEffect(() => {
    // Save the previous data (from the previous render)
    previousPropsRef.current = propsArray
    previousDataRef.current = currentData
    setPreviousData(previousDataRef.current)
    setCurrentData([...data])
  }, [data])
  const location = useLocation()
  // Check if "arq/home" is in the URL
  const pathsToCheck = ['arq/home', 'advisor-demo1/home', 'advisor-demo2/home', 'waystone/home']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))
  const isMyPerformanceInURL = location.pathname.includes('arq/home/myPerformance')
   const isRealEstateInUrl = location.pathname.includes('realestate/home')
  const ARQPathsToCheck = ['arq-mobile']

  const isArqDemo = ARQPathsToCheck.some((path) => location.pathname.includes(path))
  // Log the data to see what it looks like

  // Filtered result
  let filteredData = []
  if (isArqDemo) {
    filteredData = data.filter((item: any) => item.label !== 'Google' && item.label !== 'Custom')
  }

  const {advisor_url} = useParams()

  const MAX_CHARACTERS = 6

  // max future value
  const maxData = Math.max(...data.map((item: any) => item?.futureValue))

  //dynamically setting the angle, padding and width of chart acc to screen sizes
  const screenWidth = useScreenWidth()
  const labelTextAngle = screenWidth < 776 ? -30 : 0
  const labelFVAngle = screenWidth < 410 ? -30 : 0
  const labelPadding = screenWidth < 776 ? 8 : 0
  let chartPadding
  let _chartHeight
  if ((advisor_url || isArqInURL) && !isMyPerformanceInURL) {
    chartPadding =
      screenWidth < 546
        ? {top: 15, bottom: 70, right: 20, left: -30}
        : {top: 25, bottom: 100, right: 40, left: 10}

    _chartHeight = screenWidth > 767 ? 340 : screenWidth > 567 ? 280 : 200
  } else if (isMyPerformanceInURL) {
    chartPadding =
      screenWidth < 546
        ? {top: 15, bottom: 70, right: data.length < 3 ? 20 : 20, left: data.length < 3 ? -40 : 0}
        : {
            top: 30,
            bottom: 100,
            right: data.length < 3 ? 70 : 40,
            left: data.length < 3 ? 20 : 10,
          }

    _chartHeight = screenWidth > 767 ? 340 : screenWidth > 567 ? 280 : 200
  } else {
    chartPadding =
      screenWidth < 546
        ? {top: 10, bottom: 40, right: 40, left: 0}
        : {top: 20, bottom: 60, right: 60, left: 10}

    _chartHeight = screenWidth > 767 ? 310 : screenWidth > 567 ? 250 : 200
  }

  //custom top label of the bars
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width, lossHeight = -2000} = props

    if (data[index]?.dataValue) {
      // If dataValue Key exist exists, only display the dataValue value
      return (
        <Text
          x={x + width / 2}
          y={screenWidth > 767 ? y - 18 : y}
          textAnchor='middle'
          className='custom-label'
          fill='#9A258E'
          angle={labelFVAngle}
          onClick={() => onClickCustomBar(data[index])}
        >
          {`$${toAbbreviatedNumber(data[index]?.dataValue.toFixed(2))}`}
        </Text>
      )
    } else {
      var futureValue
      var gain
      // in-case want to show full amount on the bars, use toUSD function here instead of to abbreviated.
      if (data[index]?.type !== TYPES_OF_ROR.TYPE_SIMPLE) {
        futureValue = Math.abs(
          data[index]?.futureValue // - data[0]?.contributions - data[0]?.initialDeposit
        ).toFixed(2)
        gain = toAbbreviatedNumber(parseFloat(futureValue)) // futureValue = toUSD(props.value)
        gain = '$' + gain // remove this
      } else {
        if (data[index]?.customLabel !== '') {
          futureValue = Math.abs(
            data[index]?.futureValue // - data[0]?.contributions - data[0]?.initialDeposit
          ).toFixed(2)
          gain = toAbbreviatedNumber(parseFloat(futureValue)) // futureValue = toUSD(props.value)
          gain = '$' + gain // remove this
        } else {
          gain = '$X'
        }
      }

      return (
        <>
          <Text
            x={x + width / 2}
            y={
              screenWidth > 767
                ? data[index]?.isLoss
                  ? lossHeight
                  : y - 18
                : data[index]?.isLoss
                ? lossHeight
                : y
            }
            textAnchor='middle'
            className='custom-label'
            fill='#9A258E'
            angle={labelFVAngle}
            onClick={() => onClickCustomBar(data[index])}
          >
            {gain}
          </Text>
        </>
      )
    }
  }
  //custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, payload, index} = props
    if (data[index]?.dataKey) {
      return (
        <>
          <Text
            x={x}
            y={y + labelPadding}
            width={advisor_url ? 70 : 90}
            textAnchor='middle'
            verticalAnchor='start'
            onClick={() => onClickCustomBar(data[index])}
            fill={getCategoryForText(payload.value)}
          >
            {data[index]?.dataKey}
          </Text>
          <Text
            x={x}
            y={y + 20 + labelPadding / 2 + (advisor_url ? 3 : 0)}
            width={100}
            textAnchor='middle'
            verticalAnchor='start'
            className='custom-label'
            fill={getCategoryForText(payload.value)}
          >
            {`${data[index]?.ROR.toFixed(2)}%/yr`}
          </Text>
          <Text
            x={x}
            y={y + 40 + labelPadding / 2 + (advisor_url ? 3 : 0)}
            width={100}
            textAnchor='middle'
            verticalAnchor='start'
            className='custom-label font-size-12'
            fill={getCategoryForText(payload.value)}
            // Adjust the font size as needed
          >
            {`(${data[index]?.annualized_ROR.toFixed(2)}%)`}
          </Text>
          <Text
            x={x}
            y={y + 55 + labelPadding / 2 + (advisor_url ? 3 : 0)}
            width={100}
            textAnchor='middle'
            verticalAnchor='start'
            className='custom-label font-size-12'
            fill={getCategoryForText(payload.value)}
            // Adjust the font size as needed
          >
            {`Cash On Cash`}
          </Text>
        </>
      )
    } else {
      return (
        <>
          <Text
            x={x}
            y={y + labelPadding}
            width={advisor_url ? 70 : 90}
            textAnchor='middle'
            verticalAnchor='start'
            angle={labelTextAngle}
            className={
              payload.index > 4 && payload.value !== 'Custom'
                ? 'custom-tick-label custom-tick-label-custom'
                : 'custom-tick-label'
            }
            onClick={() => onClickCustomBar(data[index])}
            fill={getCategoryForText(payload.value)}
          >
            {data[index]?.customLabel === ''
              ? '+ Add Custom'
              : payload.value === 'VOO'
              ? 'S&P Index'
              : data[index]?.label === 'ARQ Before Exit' && isRealEstateInUrl
              ? ' Rental Income Only'
              : data[index]?.label === 'ARQ After Exit' && isRealEstateInUrl
              ? ' Rental & Sale'
              : payload.value}
          </Text>
          <Text
            x={x}
            y={y + 35 + labelPadding / 2 + (advisor_url ? 3 : 0)}
            width={100}
            textAnchor='middle'
            verticalAnchor='start'
            className='custom-label'
            fill={getCategoryForText(payload.value)}
            onClick={
              !isMyPerformanceInURL
                ? data[index]?.type === TYPES_OF_ROR.TYPE_SIMPLE
                  ? () => {
                      handleBarRateOfreturn()
                      setSelectedBar(data[index])
                    }
                  : () => {}
                : () => {}
            }
          >
            {isMyPerformanceInURL
              ? data[index]?.type === TYPES_OF_ROR.TYPE_AFTER_EXIT ||
                data[index]?.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT ||
                data[index]?.type === TYPES_OF_ROR.TYPE_RENTAL_INCOME_ONLY ||
                data[index]?.type === TYPES_OF_ROR.TYPE_RENTAL_SALE
                ? `${data[index]?.customROR.toFixed(2)}%/yr`
                : data[index]?.customROR !== null
                ? data[index]?.type === TYPES_OF_ROR.TYPE_ARQ_FLIP
                  ? `${data[index]?.customROR?.toFixed(0) ?? 0}%**`
                  : `${data[index]?.customROR?.toFixed(0) ?? 0}%`
                : // showing annualized ROR
                data[index]?.QSPAnnualizedROR !== 0
                ? `${data[index]?.QSPAnnualizedROR}%/yr`
                : data[index]?.customLabel ?? ''
              : data[index]?.customROR !== null
              ? data[index]?.type === TYPES_OF_ROR.TYPE_ARQ_FLIP
                ? `${data[index]?.customROR?.toFixed(0) ?? 0}%**`
                : `${data[index]?.customROR?.toFixed(0) ?? 0}%`
              : data[index]?.customLabel ?? ''}
          </Text>
          {isMyPerformanceInURL ? (
            <Text
              x={x}
              y={y + 55 + labelPadding / 2 + (advisor_url ? 3 : 0)}
              width={100}
              textAnchor='middle'
              verticalAnchor='start'
              className={`custom-label ${screenWidth < 546 ? 'font-size-8' : 'font-size-12'}`}
              fill={getCategoryForText(payload.value)}
            >
              {isMyPerformanceInURL
                ? data[index].type === TYPES_OF_ROR.TYPE_AFTER_EXIT ||
                  data[index].type === TYPES_OF_ROR.TYPE_BEFORE_EXIT
                  ? `(${data[index]?.totalROR?.toFixed(2)}% total)`
                  : data[index]?.chartKey === 2
                  ? `(${data[index]?.customLabel} total)`
                  : ''
                : ''}
            </Text>
          ) : null}

          {/* advisor logo */}
          {!isArqInURL && !isRealEstateInUrl ? (
            payload.value === TYPES_OF_ROR.TYPE_ARQ_FLIP ||
            payload.value === TYPES_OF_ROR.TYPE_ARQ_RENTAL ||
            payload.value === TYPES_OF_ROR.TYPE_AFTER_EXIT ||
            payload.value === TYPES_OF_ROR.TYPE_BEFORE_EXIT ? (
              <image
                href={`${process.env.REACT_APP_ADVISOR_LOGO_PATH}${receiverFormDataObj.logo_url}`}
                x={screenWidth > 767 ? x - 28 : x - 14}
                y={screenWidth > 767 ? y + 60 : y + 30}
                className='advisor-image'
                width={55}
                height={screenWidth > 767 ? payload.offset : payload.offset}
                preserveAspectRatio='xMinYMin meet'
              />
            ) : null
          ) : isArqInURL ? (
            payload.value === TYPES_OF_ROR.TYPE_ARQ_FLIP ||
            payload.value === TYPES_OF_ROR.TYPE_ARQ_RENTAL ||
            payload.value === TYPES_OF_ROR.TYPE_AFTER_EXIT ||
            payload.value === TYPES_OF_ROR.TYPE_BEFORE_EXIT ? (
              <image
                href={toAbsoluteUrl('/media/home/ARQ-logo.jpg')}
                x={screenWidth > 767 ? x - 30 : x - 30}
                y={screenWidth > 767 ? y + 90 : y + 50}
                className='advisor-image'
                width={60}
                height={screenWidth > 767 ? payload.offset : payload.offset}
                preserveAspectRatio='xMinYMin meet'
              />
            ) : null
          ) : null}
        </>
      )
    }
  }

  // Function to handle click on custom bar
  const onClickCustomBar = (props: any) => {
    if (!advisor_url || data[data.length - 1].customROR === null) {
      props.label === customBarLabel && handleCustomBar()
    }
  }

  const generateReferenceLines = () => {
    const lines = []

    // Initial deposit reference line
    if (data[0]?.initialDeposit) {
      lines.push({
        value: data[0]?.initialDeposit,
        label: `$${toAbbreviatedNumber(data[0]?.initialDeposit)}`,
      })
    }

    // Contributions reference line (added condition to exclude when contributions are zero)
    if (receiverFormDataObj.contributions > 0) {
      lines.push({
        value: data[0]?.contributions + data[0]?.initialDeposit,
        label: `$${toAbbreviatedNumber(data[0]?.contributions + data[0]?.initialDeposit)}`,
      })
    }
    return lines
  }
  // generating the reference lines
  const referenceLines = generateReferenceLines()
  //svg component for bars
  const Stack = (props: any) => (
    <svg height={props.height} width={props.width} x={props.x} y={props.y}>
      <rect width='100' height={props.height} fill={props.fill} />
    </svg>
  )

  //BenchMark for the previous bars height
  const BenchMark = (props: any) => {
    const {x, y, width, height} = props
    // Calculate the new position (inverted)
    let totalHeight = isArqInURL ? 210 - height : 220 - height
    if (screenWidth < 768) {
      // Calculate the new totalHeight for small screens while maintaining the original ratio
      totalHeight = Math.abs(isArqInURL ? 100 - height : 130 - height)
    }
    return (
      <svg>
        <line
          x1={x + 5}
          x2={x + width - 5}
          y1={totalHeight < 25 ? (screenWidth < 768 ? 20 : 40) : totalHeight}
          y2={totalHeight < 25 ? (screenWidth < 768 ? 20 : 40) : totalHeight}
          stroke='#767171'
          strokeWidth={1.5}
          strokeDasharray={'10 5'}
        />
      </svg>
    )
  }

  const renderRentSVG = (props: any) => {
    const {dataKey} = props

    return dataKey === 'Expected'
      ? projectionsColors.expected
      : dataKey === 'Actual'
      ? projectionsColors.gain
      : null
  }

  //function to render the bars shape
  const renderSVG = (props: any) => {
    if (propsArray.length < data.length) {
      propsArray.push(props)
    }
    const {
      x,
      y,
      height,
      width,
      contributions,
      initialDeposit,
      isLoss,
      futureValueWithoutContributions,
      futureValue,
      type,
      index,
      ARQ_Flip_Gain,
    } = props
    const totalHeight = height // Total height of the bar
    let contributionHeight = 0 // For types other than Simple ROR

    // Calculate normalized heights based on total height
    if (type === TYPES_OF_ROR.TYPE_SIMPLE) {
      contributionHeight =
        contributions > 0
          ? contributions > 1
            ? Math.max((contributions / futureValue) * totalHeight, 2)
            : 1
          : 0
    }

    const initialDepositHeight =
      initialDeposit > 0
        ? initialDeposit > 1
          ? Math.max((initialDeposit / futureValue) * totalHeight, 2)
          : 1
        : 0

    // includes gain equity only on my performance page
    const gainEquityHeight = (ARQ_Flip_Gain / futureValue) * totalHeight

    // Calculate the height for the benchmark based on the previous heightheightForSingleUnit = height / futureValue
    let heightForSingleUnit = height / futureValue
    const benchmarkHeight = heightForSingleUnit * previousPropsRef.current[index]?.futureValue

    // calculate loss height
    let lossHeight = Math.abs(totalHeight - contributionHeight - initialDepositHeight)
    // Calculate a new Y position to move the label above the loss bar
    let newYPosition = y - lossHeight - 20 // 20 is an additional offset to ensure it doesn't overlap, adjust as needed

    return (
      <g>
        {/* Initial deposit bar */}
        <Stack
          x={x}
          fill={projectionsColors.initialDeposit}
          width={width}
          height={Math.abs(initialDepositHeight)}
          y={y + totalHeight - initialDepositHeight}
        />
        {type === TYPES_OF_ROR.TYPE_AFTER_EXIT && isMyPerformanceInURL ? (
          <Stack
            x={x}
            fill={projectionsColors.gainOnReInvestment}
            width={width}
            height={gainEquityHeight}
            y={y + Math.abs(totalHeight - initialDepositHeight - gainEquityHeight)}
          />
        ) : null}
        {!isLoss && isMyPerformanceInURL && (
          <Stack
            x={x}
            fill={isLoss ? projectionsColors.loss : projectionsColors.gain}
            width={width}
            height={
              futureValueWithoutContributions < 0
                ? Math.abs(
                    totalHeight - contributionHeight - initialDepositHeight - gainEquityHeight
                  )
                : Math.abs(
                    totalHeight - initialDepositHeight - contributionHeight - gainEquityHeight
                  )
            }
            y={
              isLoss
                ? y -
                  Math.abs(
                    totalHeight - contributionHeight - initialDepositHeight - gainEquityHeight
                  )
                : y
            }
            isLoss={isLoss}
          />
        )}
        {/* This will render gain value of all bars other than first */}
        {!isLoss && !isMyPerformanceInURL && (
          <Stack
            x={x}
            fill={isLoss ? projectionsColors.loss : projectionsColors.gain}
            width={width}
            height={
              futureValueWithoutContributions < 0
                ? Math.abs(totalHeight - contributionHeight - initialDepositHeight)
                : Math.abs(totalHeight - initialDepositHeight - contributionHeight)
            }
            y={isLoss ? y - Math.abs(totalHeight - contributionHeight - initialDepositHeight) : y}
            isLoss={isLoss}
          />
        )}
        {/* Contribution bar */}
        <Stack
          x={x}
          fill={projectionsColors.contributions}
          width={width}
          height={Math.abs(contributionHeight)}
          y={y + totalHeight - initialDepositHeight - contributionHeight}
        />
        {/* This will render only first bar */}
        {isLoss && (
          <Stack
            x={x}
            fill={isLoss ? projectionsColors.loss : projectionsColors.gain}
            width={width}
            height={
              futureValueWithoutContributions < 0
                ? Math.abs(totalHeight - contributionHeight - initialDepositHeight)
                : Math.abs(totalHeight - initialDepositHeight - contributionHeight)
            }
            y={isLoss ? y - Math.abs(totalHeight - contributionHeight - initialDepositHeight) : y}
          />
        )}
        {isProjectionsInputChanged ? (
          <BenchMark x={x} width={width} height={benchmarkHeight} />
        ) : (
          ''
        )}
        {/* Bar Labels */}
        <CustomizedBarTopLabel
          x={x}
          y={y}
          index={index}
          width={width}
          lossHeight={newYPosition} // sending loss height
        ></CustomizedBarTopLabel>
      </g>
    )
  }

  return (
    <>
      <div
        style={{width: '100%', backgroundColor: 'white'}}
        className={isMyPerformanceInURL ? 'box-shadow' : ''}
        id='bar-chart'
      >
        {!isMyPerformanceInURL ? (
          <>
            {/* web view */}
            <div className='p-3 px-5 d-none d-md-block'>
              {/* download chart image button */}
              <Button
                className='custom-primary-button'
                onClick={() => downloadChart('chart-container', 'Vivid Projection.png')}
              >
                DOWNLOAD
              </Button>
            </div>
            {/* mobile view */}
            <div className='dropdown d-block d-md-none'>
              <button
                className='btn dropdown-toggle'
                type='button'
                id='verticalDropdown'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                <i className='bi bi-three-dots-vertical'></i>
              </button>
              <ul className='dropdown-menu' aria-labelledby='verticalDropdown'>
                {/* download chart image button */}
                <li
                  className='dropdown-item cursor-pointer'
                  onClick={() => downloadChart('chart-container', 'Vivid Projection.png')}
                >
                  DOWNLOAD
                </li>
              </ul>
            </div>
          </>
        ) : null}
        <ResponsiveContainer width='100%' height={_chartHeight} id='chart-container'>
          <BarChart
            style={{backgroundColor: 'white'}}
            data={currentData}
            margin={chartPadding}
            barSize={screenWidth < 546 ? 50 : 100}
            barCategoryGap={10} // Adjust the gap between bars in a category (group)
            barGap={5} // Adjust the gap between different categories (groups)
          >
            {screenWidth > 768
              ? referenceLines.map((line, index) => (
                  <ReferenceLine
                    key={index}
                    y={line.value}
                    yAxisId='left'
                    stroke='#CCCCCC'
                    strokeDasharray='2 5'
                    textAnchor='bottom'
                    label={{
                      dy: index === 0 ? 4 : -4,
                      position: 'left',
                      value: line.label,
                      className: 'custom-tick-label',
                    }}
                    className='custom-tick-label reference-line-label'
                  />
                ))
              : null}

            <ReferenceArea
              x1={
                !isMyPerformanceInURL
                  ? receiverFormDataObj.selected_rate_of_return_obj.label || undefined
                  : undefined
              }
              x2={
                !isMyPerformanceInURL
                  ? receiverFormDataObj.selected_rate_of_return_obj.label || undefined
                  : undefined
              }
              stroke='white'
              strokeWidth={3}
              fill='#a1c8cd'
              yAxisId={'left'}
              isFront={true}
            />
            <ReferenceArea
              x1={'ARQ Fix & Flip'}
              x2={'ARQ Fix & Flip'}
              stroke='white'
              strokeWidth={3}
              fill='#a1c8cd'
              yAxisId={'left'}
              isFront={true}
            />
            <ReferenceArea
              x1={'ARQ Rental'}
              x2={'ARQ Rental'}
              stroke='white'
              fill='#a1c8cd'
              strokeWidth={3}
              yAxisId={'left'}
              isFront={true}
            />
            <ReferenceArea
              x1={customBarLabel}
              x2={customBarLabel}
              stroke='white'
              fill='transparent'
              strokeWidth={3}
              yAxisId={'left'}
              isFront={true}
              onClick={handleCustomBar}
            />
            <XAxis
              dataKey='label'
              axisLine={false}
              tickLine={false}
              interval={0}
              minTickGap={5}
              tick={<CustomizedAxisTick />}
            />
            <YAxis
              yAxisId='left'
              allowDataOverflow={true}
              tick={false}
              axisLine={false}
              tickLine={false}
              domain={[
                (dataMin: number) => 0 - Math.abs(dataMin),
                (dataMax: number) => dataMax * 1.2, // Increase dataMax by 10%
              ]}
            />
            <Tooltip
              contentStyle={{backgroundColor: 'white'}}
              content={isMyPerformanceInURL ? <MyPerformanceTooltip /> : <ProjectionChartTooltip />}
            />
            <Bar
              dataKey='futureValue'
              yAxisId='left'
              stackId='assets'
              onClick={onClickCustomBar}
              shape={(props) => {
                if (data) {
                  createCustomBarLabel(data)
                }
                return renderSVG(props)
              }}
            ></Bar>
            <Bar
              dataKey='dataValue'
              yAxisId='left'
              stackId='assets'
              onClick={onClickCustomBar}
              shape={(props) => {
                const fillColor = renderRentSVG(props)
                return <rect {...props} fill={fillColor} />
              }}
            >
              <LabelList content={<CustomizedBarTopLabel />} position='top' />
            </Bar>
            {goalSettingBar.goalValue > 0 ? (
              <>
                <ReferenceLine
                  y={goalSettingBar.goalValue <= maxData ? goalSettingBar.goalValue : maxData * 1.2}
                  yAxisId='left'
                  stroke='green'
                  textAnchor='bottom'
                  isFront={true}
                  label={{
                    position: 'right',
                    // value: `${goalSettingBar.goalName}`,
                    value: `${
                      goalSettingBar.goalName.length > MAX_CHARACTERS
                        ? goalSettingBar.goalName.slice(0, MAX_CHARACTERS) + '...'
                        : goalSettingBar.goalName
                    }`,
                    className: 'custom-tick-label',
                  }}
                  className='custom-tick-label reference-line-label'
                />
                <ReferenceLine
                  y={goalSettingBar.goalValue <= maxData ? goalSettingBar.goalValue : maxData * 1.2}
                  yAxisId='left'
                  stroke='transparent'
                  textAnchor='bottom'
                  isFront={true}
                  label={{
                    dy: 10,
                    position: 'right',
                    value:
                      goalSettingBar.goalValue <= maxData
                        ? `${toAbbreviatedNumber(goalSettingBar.goalValue)}`
                        : `^${toAbbreviatedNumber(goalSettingBar.goalValue)}`,
                    className: 'custom-tick-label',
                  }}
                  className='custom-tick-label reference-line-label'
                />
              </>
            ) : null}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
export default ProjectionsChart
