import React, {createContext, useState, useContext, Dispatch, SetStateAction, useMemo} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {CustomInvestments} from '../modules/advisor/components/_model'
import {
  assets,
  defaultAsset,
  typesOfRoR,
} from '../modules/homeComponents/projections/components/ProjectionsConfig'
import {CompoundingGrowth} from '../modules/helper/calculator/_model'
import {sectionIds} from '../../constants/AppConstants'
const isWaystoneInURL = window.location.pathname.includes('waystone')

type ReceiverFormData = {
  starting_amount: number
  contributions: number
  contributions_frequency: number
  // rate_of_return: number
  custom_investment_values: CustomInvestments[]
  years_to_grow: number
  greetings: string
  logo_url: string
  ARQ_Flip_Gain: number
  ARQ_rental_income: number
  ARQ_rental_contribution: number
  ARQ_appreciation: number
  ARQ_property_worth: number
  management_fee: number
  selected_rate_of_return_obj: CompoundingGrowth
  custom_email: string
  selected_bars: Array<number>
}

type GoalSettingData = {
  goalName: string
  goalValue: number
}

type OriginalARQValues = {
  proportionValue: number | null
  fixAndFlip: number | null
  rental: number | null
  appreciation: number | null
  management_fee: number
}

type TimeSeriesBarGraphData = {
  yearOrMonth: string
  isMonthly: boolean | null
  futureValue: number
  gain: number
  initialDeposit: number | null
  contributionsThisPeriod: number | null
  flipGain: number | null
  rentalIncome: number | null
  appreciation: number | null
  isLoss: boolean
  originalARQValues: OriginalARQValues
  lastValues: {contributions: number; gain: number; yearOrMonth: string}
}

type projectionsSentence = {
  startingAmount: string
  contributions: string
  numberOfYears: any
  contributionFrequency: string | any
  rateOfReturn: string | null
  futureValue: string
}

type previousDataType = any[]

type ReceiverFormContextProps = {
  receiverFormDataObj: ReceiverFormData
  setReceiverFormDataObj: Dispatch<SetStateAction<ReceiverFormData>>
  isReceiverDataLoading: boolean | undefined
  setIsReceiverDataLoading: Dispatch<SetStateAction<boolean>>
  receiverURL: string | undefined
  setReceiverURL: Dispatch<SetStateAction<string>>
  pictoFutureValue: number | undefined
  setPictoFutureValue: Dispatch<SetStateAction<number>>
  timeSeriesGraphDataObj: TimeSeriesBarGraphData[]
  setTimeSeriesGraphDataObj: Dispatch<SetStateAction<TimeSeriesBarGraphData[]>>
  goalSettingBar: GoalSettingData
  setGoalSettingBar: Dispatch<SetStateAction<GoalSettingData>>
  previousData: previousDataType
  setPreviousData: Dispatch<SetStateAction<previousDataType>>
  isSticky: boolean
  setIsSticky: Dispatch<SetStateAction<boolean>>
  distanceFromTop: number
  setDistanceFromTop: Dispatch<SetStateAction<number>>
  isProjectionsInputChanged: boolean
  setIsProjectionsInputChanged: Dispatch<SetStateAction<boolean>>
  projectionsSentence: projectionsSentence
  setProjectionsSentence: Dispatch<SetStateAction<projectionsSentence>>
  sectionIdToShowInWebViewer: string
  setSectionIdToShowInWebViewer: Dispatch<SetStateAction<string>>
  showInWebViewer: boolean
  setShowInWebViewer: Dispatch<SetStateAction<boolean>>
  scrolledInwebViewer: boolean
  setScrolledInwebViewer: Dispatch<SetStateAction<boolean>>
  selectedBar: any
  setSelectedBar: Dispatch<SetStateAction<any>>
  stickyStatus: any
  setStickyStatus: Dispatch<SetStateAction<any>>
}

// default values
const initReceiverContextPropsState = {
  receiverFormDataObj: {
    starting_amount: 1000.0,
    contributions: 0,
    contributions_frequency: 1,
    custom_investment_values: [
      {
        id: 0,
        type_rate_of_return: typesOfRoR[0].type,
        investment_name: '',
        investment_value: 0,
      },
    ],
    years_to_grow: 10,
    greetings: '',
    logo_url: 'logo_url',
    ARQ_Flip_Gain: 0,
    ARQ_rental_income: 100,
    ARQ_rental_contribution: 12,
    ARQ_appreciation: 0,
    ARQ_property_worth: 10000,
    management_fee: 80,
    selected_rate_of_return_obj: assets[defaultAsset],
    custom_email: '',
    selected_bars: [0, 1, 2, 3, 4, 7],
  },
  setReceiverFormDataObj: () => {},
  isReceiverDataLoading: true,
  setIsReceiverDataLoading: () => {},
  receiverURL: '',
  setReceiverURL: () => {},
  pictoFutureValue: 0,
  setPictoFutureValue: () => {},
  // time series bar graph obj
  timeSeriesGraphDataObj: [] as TimeSeriesBarGraphData[],
  setTimeSeriesGraphDataObj: () => {},
  goalSettingBar: {
    goalName: '',
    goalValue: 0.0,
  },
  setGoalSettingBar: () => {},
  previousData: [],
  setPreviousData: () => {},
  isSticky: false,
  setIsSticky: () => {},
  distanceFromTop: 0,
  setDistanceFromTop: () => {},
  isProjectionsInputChanged: false,
  setIsProjectionsInputChanged: () => {},
  projectionsSentence: {} as projectionsSentence,
  setProjectionsSentence: () => {},
  sectionIdToShowInWebViewer: '',
  setSectionIdToShowInWebViewer: () => {},
  showInWebViewer: false,
  setShowInWebViewer: () => {},
  scrolledInwebViewer: false,
  setScrolledInwebViewer: () => {},
  selectedBar: {},
  setSelectedBar: () => {},
  stickyStatus: {},
  setStickyStatus: () => {},
}

// default values when URL contains /arq/home
const initARQReceiverContextPropsState = {
  receiverFormDataObj: {
    starting_amount: 50000,
    contributions: 0,
    contributions_frequency: 1,
    // rate_of_return: 4.5,
    custom_investment_values: [
      {
        id: 0,
        type_rate_of_return: typesOfRoR[0].type,
        investment_name: '',
        investment_value: 0,
      },
    ],
    years_to_grow: isWaystoneInURL ? 10 : 3,
    greetings: '',
    logo_url: 'logo_url',
    ARQ_Flip_Gain: 510000,
    ARQ_rental_income: 25500,
    ARQ_rental_contribution: 4,
    ARQ_appreciation: 0,
    ARQ_property_worth: 1275000,
    management_fee: 80,
    selected_rate_of_return_obj: assets[isWaystoneInURL ? 7 : 3],
    custom_email: '',
    selected_bars: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  setReceiverFormDataObj: () => {},
  isReceiverDataLoading: true,
  setIsReceiverDataLoading: () => {},
  receiverURL: '',
  setReceiverURL: () => {},
  pictoFutureValue: 0,
  setPictoFutureValue: () => {},
  // projectionsSentence: {
  //   startingAmount: '$0',
  //   contributions: '$0',
  //   numberOfYears: 0,
  //   contributionFrequency: 'month',
  //   rateOfReturn: assets[defaultAsset].dropdownLabel,
  //   futureValue: '$0',
  // },
  projectionsSentence: {} as projectionsSentence,
  setProjectionsSentence: () => {},
  sectionIdToShowInWebViewer: '',
  setSectionIdToShowInWebViewer: () => {},
  showInWebViewer: false,
  setShowInWebViewer: () => {},
  scrolledInwebViewer: false,
  setScrolledInwebViewer: () => {},
  selectedBar: {},
  setSeelectedBar: () => {},
}

const ReceiverFormContext = createContext<ReceiverFormContextProps>(initReceiverContextPropsState)

const ReceiverFormProvider: React.FC<WithChildren> = React.memo(({children}) => {
  const location = window.location.pathname
  const pathsToCheck = [
    'arq/home',
    'advisor-demo1/home',
    'advisor-demo2/home',
    'waystone/home',
    'arq/create',
  ]
  const isArqInURL = pathsToCheck.some((path) => location.includes(path))
  const isRealEstateInUrl = location.includes('realestate/home')

  const [receiverFormDataObj, setReceiverFormDataObj] = useState(
    isArqInURL|| isRealEstateInUrl      ? initARQReceiverContextPropsState.receiverFormDataObj
      : initReceiverContextPropsState.receiverFormDataObj
  )
  const [isReceiverDataLoading, setIsReceiverDataLoading] = useState(true)
  const [receiverURL, setReceiverURL] = useState('')
  const [pictoFutureValue, setPictoFutureValue] = useState(0)
  const [projectionsSentence, setProjectionsSentence] = useState(
    isArqInURL || isRealEstateInUrl
      ? initARQReceiverContextPropsState.projectionsSentence
      : initReceiverContextPropsState.projectionsSentence
  )
  const [timeSeriesGraphDataObj, setTimeSeriesGraphDataObj] = useState<TimeSeriesBarGraphData[]>(
    initReceiverContextPropsState.timeSeriesGraphDataObj
  )
  const [goalSettingBar, setGoalSettingBar] = useState(initReceiverContextPropsState.goalSettingBar)
  const [previousData, setPreviousData] = useState<previousDataType>([])
  const [isSticky, setIsSticky] = useState(false)
  const [distanceFromTop, setDistanceFromTop] = useState(0)
  const [isProjectionsInputChanged, setIsProjectionsInputChanged] = useState<boolean>(false)
  const [sectionIdToShowInWebViewer, setSectionIdToShowInWebViewer] = useState<string>('')
  const [showInWebViewer, setShowInWebViewer] = useState<boolean>(false)
  const [scrolledInwebViewer, setScrolledInwebViewer] = useState<boolean>(false)
  const [selectedBar, setSelectedBar] = useState<any>({})
  const [stickyStatus, setStickyStatus] = useState(
    Array.from({length: sectionIds.length}, (_, index) => index).reduce(
      (acc, id) => ({...acc, [id]: false}),
      {}
    )
  )

  // This useMemo ensures that the context value only changes when any of the context properties change
  const contextValue = useMemo(
    () => ({
      receiverFormDataObj,
      setReceiverFormDataObj,
      isReceiverDataLoading,
      setIsReceiverDataLoading,
      receiverURL,
      setReceiverURL,
      pictoFutureValue,
      setPictoFutureValue,
      timeSeriesGraphDataObj,
      setTimeSeriesGraphDataObj,
      goalSettingBar,
      setGoalSettingBar,
      previousData,
      setPreviousData,
      isSticky,
      setIsSticky,
      distanceFromTop,
      setDistanceFromTop,
      isProjectionsInputChanged,
      setIsProjectionsInputChanged,
      projectionsSentence,
      setProjectionsSentence,
      showInWebViewer,
      setShowInWebViewer,
      sectionIdToShowInWebViewer,
      setSectionIdToShowInWebViewer,
      scrolledInwebViewer,
      setScrolledInwebViewer,
      selectedBar,
      setSelectedBar,
      stickyStatus,
      setStickyStatus,
    }),
    [
      receiverFormDataObj,
      isReceiverDataLoading,
      receiverURL,
      pictoFutureValue,
      timeSeriesGraphDataObj,
      goalSettingBar,
      previousData,
      isSticky,
      isProjectionsInputChanged,
      projectionsSentence,
      sectionIdToShowInWebViewer,
      showInWebViewer,
      scrolledInwebViewer,
      selectedBar,
      distanceFromTop,
      stickyStatus,
    ]
  )
  return (
    <ReceiverFormContext.Provider value={contextValue}>{children}</ReceiverFormContext.Provider>
  )
})

const useReceiverFormContext = () => {
  const context = useContext(ReceiverFormContext)
  if (!context) {
    throw new Error('useReceiverFormContext must be used within a ReceiverFormProvider')
  }
  return context
}

export {ReceiverFormProvider, useReceiverFormContext}
