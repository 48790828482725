import {useRef, useEffect, useState} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Spinner} from 'react-bootstrap'
import MetaTags from '../../../Meta/MetaTags'
import LifestyleSection from '../../modules/homeComponents/LifestyleSection'
import HeroSection from '../../modules/homeComponents/HeroSection'
import SameMathNewLookSection from '../../modules/homeComponents/SameMathNewLookSection'
import BeginnersSection from '../../modules/homeComponents/BeginnersSection'
import StayTunedSection from '../../modules/homeComponents/StayTunedSection'
import FinanciallyFreeSection from '../../modules/homeComponents/FinanciallyFreeSection'
import ProjectionsSection from '../../modules/homeComponents/projections/ProjectionsSection'
import {InvestmentTableModel} from '../../modules/helper/calculator/_model'
import ProjectionsTable from '../../modules/homeComponents/projections/components/ProjectionsTable'
import {fetchAdvisorFormData} from '../../modules/advisor/core/_request'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import {
  assets,
  defaultAsset,
} from '../../modules/homeComponents/projections/components/ProjectionsConfig'
import {
  BEGINNERS_SECTION,
  FINANCIAL_SECTION,
  PROJECTIONS_TABLE,
  TYPES_OF_ROR,
} from '../../../constants/AppConstants'
import TimeSeriesSection from '../../modules/homeComponents/TSBG/TimeSeriesSection'
import useScreenWidth from '../../modules/hooks/useScreenWidth'
import Pictograph from '../../modules/homeComponents/pictograph/Pictograph'
import BottomLineSection from '../../modules/homeComponents/bottomLine/BottomLineSection'
import {StickyContainer} from '../../modules/homeComponents/projections/components/StickyContainer'
import {useScroll, useTransform} from 'framer-motion'
import {sectionIds} from '../../../constants/AppConstants'
import SBLOCSection from '../../modules/homeComponents/SBLOC/SBLOCSection'

const ARQPage = () => {
  const {advisor_url} = useParams()
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)
  const [manualScrollOccurred, setManualScrollOccurred] = useState(false)
  const formRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const formElement = formRef.current

    const handleScroll = () => {
      if (formElement) {
        const boundingBox = formElement.getBoundingClientRect()
        if (boundingBox.top <= 40) {
          setIsSticky(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  // // extracting useparams
  const ProjectionsWebSectionRef = useRef<HTMLDivElement>(null)
  const ProjectionsWebInputsRef = useRef<HTMLDivElement>(null)
  // Use the useScreenWidth hook to get the screen width
  const screenWidth = useScreenWidth()
  const location = useLocation()
  // Check if "arq" is in the URL
  const pathsToCheck = ['arq/home', 'advisor-demo1/home', 'advisor-demo2/home', 'waystone/home']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))
  const isAghazInUrl = location.pathname.includes('aghaz')
  const isRealEstateInUrl = location.pathname.includes('realestate/home')
  const isWaystoneInURL = location.pathname.includes('waystone')

  // destructuring useReceiverFormContext hook
  const {
    isReceiverDataLoading,
    setIsReceiverDataLoading,
    setReceiverFormDataObj,
    isSticky,
    setIsSticky,
  } = useReceiverFormContext()

  useEffect(() => {
    if ((isArqInURL || isRealEstateInUrl) && !isWaystoneInURL) {
      // extracting last index of assets array(assuming that last index is always a custom bar)
      const customAssetIndex = assets.length - 1
      // Update the value for the (Rate of Return) object with the label "Custom"
      assets[customAssetIndex].label = 'Google'
      assets[customAssetIndex].dropdownLabel = `(25%) Google`
      assets[customAssetIndex].customLabel = `25%`
      assets[customAssetIndex].value = 25
    }
    if (isWaystoneInURL) {
      // extracting last index of assets array(assuming that last index is always a custom bar)
      const customAssetIndex = assets.length - 1
      // Update the value for the (Rate of Return) object with the label "Custom"
      assets[customAssetIndex].label = 'Assetmark LargeCap'
      assets[customAssetIndex].dropdownLabel = `(10%) Assetmark LargeCap`
      assets[customAssetIndex].customLabel = `10%`
      assets[customAssetIndex].value = 10
    }
  }, [])

  // stay tuned ref
  const stayTunedSectionRef = useRef<HTMLDivElement>(null)
  // projections ref

  // handle click stay tuned section
  const handleClickStayTuned = () => {
    if (stayTunedSectionRef.current) {
      const scrollOptions: ScrollToOptions = {
        top: stayTunedSectionRef.current.offsetTop,
        behavior: 'smooth',
      }
      window.scrollTo(scrollOptions)
    }
  }

  const handleClickProjections = () => {
    const projectionsDiv = document.getElementById(sectionIds[0])
    if (projectionsDiv) {
      projectionsDiv.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  // Add an event listener to detect manual scroll
  useEffect(() => {
    const handleScroll = () => {
      // Manual scroll has occurred, set the state variable to true
      setManualScrollOccurred(true)
    }

    // Add the event listener to the window
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  // Conditionally call the appropriate scroll function based on screen width
  useEffect(() => {
    // Check if manual scroll has occurred
    if (!manualScrollOccurred) {
      // Set up the setTimeout with a 10-second delay
      const scrollTimerId = setTimeout(() => {
        handleClickProjections()
      }, 7000)

      // Clean up the setTimeout on component unmount to avoid memory leaks
      return () => {
        clearTimeout(scrollTimerId)
      }
    }
  }, [screenWidth, manualScrollOccurred])
  const [tableData, setTableData] = useState<InvestmentTableModel[]>([])
  const handleDataChange = (newData: InvestmentTableModel[]) => {
    // Process the newData or update the state accordingly
    setTableData(newData)
  }
  const targetRef = useRef(null)
  const {scrollYProgress} = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })
  const handleChartDataChange = (newData: InvestmentTableModel[]) => {
    // Process the newData or update the state accordingly
    setTableData(newData)
  }
  const backgroundImageY = useTransform(scrollYProgress, [0.45, 1], ['0%', '-50%'])

  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />

      <section className='container content'>
        <StickyContainer
          handleClickProjections={handleClickProjections}
          handleClickStayTuned={handleClickStayTuned}
          refProp={ProjectionsWebSectionRef}
        ></StickyContainer>

        <div ref={formRef}></div>

        {/* div to remain the  overflow container */}
        <div className='container-to-show-parallax'>
          {/* {!isReceiverDataLoading && advisor_url ? <PastPerformance /> : null} */}
          <Pictograph />
          <LifestyleSection />
          <SameMathNewLookSection />
          <BeginnersSection
            subHeading={BEGINNERS_SECTION.VISUALIZATION}
            paragraph={BEGINNERS_SECTION.OUR_MISSION}
            secondParagraph=''
          />
          {isWaystoneInURL ? <SBLOCSection /> : null}
          <StayTunedSection refProp={stayTunedSectionRef} />
          <FinanciallyFreeSection
            heading={FINANCIAL_SECTION.FINANCIALLY_FREE}
            subHeading={FINANCIAL_SECTION.FULL_POTENTIAL}
            paragraph1={FINANCIAL_SECTION.FREEDOM}
            paragraph2={FINANCIAL_SECTION.PEACE}
            paragraph3={FINANCIAL_SECTION.MISSION}
            paragraph4={FINANCIAL_SECTION.ABUNDANCE}
          />
        </div>
      </section>
    </>
  )
}

export {ARQPage}
