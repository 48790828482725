import {useEffect, useState} from 'react'
import BeginnersSection from '../../modules/homeComponents/BeginnersSection'
import {ABOUT_PAGE, sectionIds} from '../../../constants/AppConstants'
import FinanciallyFreeSection from '../../modules/homeComponents/FinanciallyFreeSection'
import HeroSection from '../../modules/homeComponents/HeroSection'
import JoinInterestList from './components/JoinInterestList'
import useScreenWidth from '../../modules/hooks/useScreenWidth'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import {useLocation} from 'react-router-dom'
import PossibilitiesGifSection from '../../modules/homeComponents/PossibilitiesGifSection'
import ExplainHardConcepts from '../../modules/homeComponents/ExplainHardConcepts'
import FeatureSuggestionSection from '../../modules/homeComponents/FeatureSuggestions/FeatureSuggestionSection'
import SameMathNewLookSection from '../../modules/homeComponents/SameMathNewLookSection'

function AboutPage() {
  const screenWidth = useScreenWidth()
  const [manualScrollOccurred, setManualScrollOccurred] = useState(false)
  const {
    setIsReceiverDataLoading,
    showInWebViewer,
    setScrolledInwebViewer,
    setShowInWebViewer,
    sectionIdToShowInWebViewer,
    setSectionIdToShowInWebViewer,
  } = useReceiverFormContext()
  const location = useLocation()
  let hash = location.hash

  // Split the string from the location of the '?'
  let [sectionId, queryString] = hash.split('?')

  // Split the query string into individual parameters
  const params = queryString?.split('&')
  sectionId = sectionId.substring(1)
  // Create an object to store the key-value pairs
  const queryObj: {[key: string]: string} = {}

  // Iterate through the parameters and populate the query object
  params?.forEach((param) => {
    const [key, value] = param.split('=')
    queryObj[key] = value
  })

  //setting the zoom percentage
  if (queryObj.zoom) {
    const zoomValue = parseFloat(queryObj.zoom)
    const scaleFactor = zoomValue / 100
    document.body.style.transform = `scale(${scaleFactor})`
  } else if (queryObj.showInWebViewer === 'true') {
    // Reset the transform property to the default scale (0.85)
    document.body.style.transform = 'scale(0.85)'
  }

  //setting sectionId in receiver context , which is coming in params
  setSectionIdToShowInWebViewer(sectionId)
  setShowInWebViewer(queryObj.showInWebViewer === 'true' ? true : false)

  const handleClickProjections = () => {
    const projectionsDiv = document.getElementById(sectionIds[7])

    if (projectionsDiv) {
      projectionsDiv.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }
  const handleClickStayTuned = () => {
    const projectionsDiv = document.getElementById(sectionIds[16])

    if (projectionsDiv) {
      projectionsDiv.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      // Manual scroll has occurred, set the state variable to true
      setManualScrollOccurred(true)
      showInWebViewer ? setScrolledInwebViewer(true) : setScrolledInwebViewer(false)
    }

    // Add the event listener to the window
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  useEffect(() => {
    setIsReceiverDataLoading(false)

    const scrollTimerId = setTimeout(() => {
      const projectionsDiv = document.getElementById(sectionIds[7])
      if (projectionsDiv) {
        projectionsDiv.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }, 3000) // Adjust the time to 3000 milliseconds (3 seconds)

    return () => {
      clearTimeout(scrollTimerId)
    }
  }, [])
  useEffect(() => {
    setIsReceiverDataLoading(false)

    // Check if manual scroll has occurred
    if (!manualScrollOccurred) {
      // Set up the setTimeout with a 10-second delay
      const scrollTimerId = setTimeout(() => {
        handleClickProjections()
      }, 4000)

      // Clean up the setTimeout on component unmount to avoid memory leaks
      return () => {
        clearTimeout(scrollTimerId)
      }
    }
  }, [screenWidth, manualScrollOccurred])

  const {REASON_SECTION, BEGINNERS_SECTION} = ABOUT_PAGE
  return (
    <>
      <div className='container snapping-container'>
        <div className='container-custom m-auto'>
          {/* hero section */}
          <section
            id={sectionIds[12]}
            className={`snap-section flex-column overflow-hidden ${
              showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[12] ? 'd-none' : ''
            }`}
          >
            <HeroSection
              handleClickProjections={handleClickProjections}
              handleClickStayTuned={handleClickStayTuned}
            />
          </section>
          <section
            id={sectionIds[7]}
            className={`snap-section h-110vh ${
              showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[7] ? 'd-none' : ''
            }`}
          >
            <PossibilitiesGifSection
              subHeading={BEGINNERS_SECTION.VISUALIZATION}
              paragraph={BEGINNERS_SECTION.OUR_MISSION}
              secondParagraph={BEGINNERS_SECTION.SECOND_MISSION}
            />
          </section>
          {/* explain hard visuals section */}
          <section
            id={sectionIds[19]}
            className={`snap-section h-100vh ${
              showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[7] ? 'd-none' : ''
            }`}
          >
            <ExplainHardConcepts
              subHeading={BEGINNERS_SECTION.VISUALIZATION}
              paragraph={BEGINNERS_SECTION.OUR_MISSION}
              secondParagraph={BEGINNERS_SECTION.SECOND_MISSION}
            />
          </section>

          {/* same math new look section */}
          <section
            id={sectionIds[5]}
            className={`snap-section h-100vh ${
              showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[7] ? 'd-none' : ''
            }`}
          >
            <SameMathNewLookSection />
          </section>

          {/* suggested  feature section */}
          <section
            id={sectionIds[9]}
            className={`snap-section h-100vh ${
              showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[7] ? 'd-none' : ''
            }`}
          >
            <FeatureSuggestionSection />
          </section>

          {/* beginner section */}
          <section
            id={sectionIds[7]}
            className={`snap-section h-110vh ${
              showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[7] ? 'd-none' : ''
            }`}
          >
            <BeginnersSection
              subHeading={BEGINNERS_SECTION.VISUALIZATION}
              paragraph={BEGINNERS_SECTION.OUR_MISSION}
              secondParagraph={BEGINNERS_SECTION.SECOND_MISSION}
            />
          </section>
          {/* join interest list section */}
          <section
            id={sectionIds[16]}
            className={`snap-section h-100vh ${
              showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[16] ? 'd-none' : ''
            }`}
          >
            <JoinInterestList />
          </section>
          {/* financial freedom section */}
          <section
            id={sectionIds[10]}
            className={`snap-section ${
              showInWebViewer && sectionIdToShowInWebViewer !== sectionIds[10] ? 'd-none' : ''
            }`}
          >
            <FinanciallyFreeSection
              heading={REASON_SECTION.RE_DEFINING}
              subHeading={REASON_SECTION.ACCESSIBLE}
              paragraph1={REASON_SECTION.BEFORE_AFTER}
              paragraph2={REASON_SECTION.BUSINESSES}
              paragraph3={REASON_SECTION.EVERYONE}
              isAboutPage={true}
            />
          </section>
        </div>
      </div>
    </>
  )
}

export default AboutPage
