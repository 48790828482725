import {useRef, useEffect, useState} from 'react'
import MetaTags from '../../../Meta/MetaTags'
import LifestyleSection from '../../modules/homeComponents/LifestyleSection'
import SameMathNewLookSection from '../../modules/homeComponents/SameMathNewLookSection'
import BeginnersSection from '../../modules/homeComponents/BeginnersSection'
import StayTunedSection from '../../modules/homeComponents/StayTunedSection'
import FinanciallyFreeSection from '../../modules/homeComponents/FinanciallyFreeSection'
import {InvestmentTableModel} from '../../modules/helper/calculator/_model'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import Pictograph from '../../modules/homeComponents/pictograph/Pictograph'
import useScreenWidth from '../../modules/hooks/useScreenWidth'
import {StickyContainer} from '../../modules/homeComponents/projections/components/StickyContainer'
import SBLOCSection from '../../modules/homeComponents/SBLOC/SBLOCSection'
import FeedbackSection from '../../modules/homeComponents/feedbackSection/FeedbackSection'
import {useLocation} from 'react-router-dom'
// import FeatureSuggestionSection from '../../modules/homeComponents/FeatureSuggetions/FeatureSuggestionSection'
import {BEGINNERS_SECTION, FINANCIAL_SECTION} from '../../../constants/AppConstants'
import {sectionIds} from '../../../constants/AppConstants'
import ComparePerformanceSection from '../../modules/homeComponents/comparePerformance/ComparePerformanceSection'
import PastPerformance from '../../modules/homeComponents/PastPerformance/PastPerformance'
import FeatureSuggestionSection from '../../modules/homeComponents/FeatureSuggestions/FeatureSuggestionSection'
const HomePage = () => {
  const [tableData, setTableData] = useState<InvestmentTableModel[]>([])
  const {
    isReceiverDataLoading,
    setIsReceiverDataLoading,
    setSectionIdToShowInWebViewer,
    setShowInWebViewer,
  } = useReceiverFormContext()
  const [manualScrollOccurred, setManualScrollOccurred] = useState(false)
  // stay tuned ref
  const stayTunedSectionRef = useRef<HTMLDivElement>(null)
  // projections ref
  const ProjectionsSectionRef = useRef<HTMLDivElement>(null)
  const ProjectionsWebSectionRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLDivElement | null>(null)

  // Use the useScreenWidth hook to get the screen width
  const screenWidth = useScreenWidth()
  const location = useLocation()
  let hash = location.hash

  // Split the string from the location of the '?'
  let [sectionId, queryString] = hash.split('?')
  const formattedSectionId = sectionId.replace(/^#/, '')
  const isCompareActualInURL = location.search.includes('CompareActual=true')

  useEffect(() => {
    const formElement = formRef.current

    const handleScroll = () => {
      if (formElement) {
        const boundingBox = formElement.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop

        if (boundingBox.top <= 0 && scrollTop > 0) {
          formElement.classList.add('sticky') // Add a "sticky" class
        } else {
          formElement.classList.remove('sticky') // Remove the "sticky" class
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // handle click stay tuned section
  const handleClickStayTuned = () => {
    if (stayTunedSectionRef.current) {
      const scrollOptions: ScrollToOptions = {
        top: stayTunedSectionRef.current.offsetTop,
        behavior: 'smooth',
      }
      window.scrollTo(scrollOptions)
    }
  }

  // embeding a specific section in powerpoint

  // Split the query string into individual parameters
  const params = queryString?.split('&')
  sectionId = sectionId.substring(1)

  // Create an object to store the key-value pairs
  const queryObj: {[key: string]: string} = {}

  // Iterate through the parameters and populate the query object
  params?.forEach((param) => {
    const [key, value] = param.split('=')
    queryObj[key] = value
  })

  //setting the zoom percentage
  if (queryObj.zoom) {
    const zoomValue = parseFloat(queryObj.zoom)
    const scaleFactor = zoomValue / 100
    document.body.style.transform = `scale(${scaleFactor})`
  } else if (queryObj.showInWebViewer === 'true') {
    // Reset the transform property to the default scale (0.85)
    document.body.style.transform = 'scale(0.85)'
  }

  //setting sectionId in receiver context , which is coming in params
  setSectionIdToShowInWebViewer(sectionId)
  setShowInWebViewer(queryObj.showInWebViewer === 'true' ? true : false)

  const handleClickProjections = () => {
    const projectionsDiv = document.getElementById(sectionIds[0])
    if (projectionsDiv) {
      projectionsDiv.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  // Add an event listener to detect manual scroll
  const handleSpecificSection = (sectionId: string) => {
    const trendingSection = document.getElementById(sectionId)
    if (trendingSection) {
      trendingSection.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }
  useEffect(() => {
    const handleScroll = () => {
      // Manual scroll has occurred, set the state variable to true
      setManualScrollOccurred(true)
    }

    // Add the event listener to the window
    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [screenWidth, manualScrollOccurred])

  useEffect(() => {
    setIsReceiverDataLoading(false)

    let scrollTimerId: any

    if (!manualScrollOccurred && (formattedSectionId === sectionIds[12] || !formattedSectionId)) {
      // Set up the setTimeout with a 10-second delay
      scrollTimerId = setTimeout(() => {
        handleClickProjections()
      }, 5000) // 5 seconds in milliseconds
    } else {
      scrollTimerId = setTimeout(() => {
        handleSpecificSection(formattedSectionId)
      }, 1000)
    }

    // Clean up the setTimeout on component unmount to avoid memory leaks
    return () => {
      clearTimeout(scrollTimerId)
    }
  }, [screenWidth, manualScrollOccurred])

  const webViewerRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (webViewerRef.current) {
      // Access the web viewer's window and document objects using optional chaining
      const webViewerWindow = webViewerRef.current.contentWindow

      if (webViewerWindow) {
        // Access the local URL of the web viewer
        const localUrl = webViewerWindow.location.href
      }
    }
  }, [screenWidth, manualScrollOccurred])
  const handleDataChange = (newData: InvestmentTableModel[]) => {
    // Process the newData or update the state accordingly
    setTableData(newData)
  }
  return (
    <>
      {/* meta tags for SEO */}
      <MetaTags URL={window.location.href} />

      <section className='container content'>
        <StickyContainer
          handleClickProjections={handleClickProjections}
          handleClickStayTuned={handleClickStayTuned}
          refProp={ProjectionsWebSectionRef}
        ></StickyContainer>
        <div ref={formRef}></div>

        {/* contrainer in which parallax works */}
        <div className='container-to-show-parallax'>
          {/* <StayTunedSection refProp={stayTunedSectionRef} /> */}
          <BeginnersSection
            subHeading={BEGINNERS_SECTION.VISUALIZATION}
            paragraph={BEGINNERS_SECTION.OUR_MISSION}
            secondParagraph=''
          />
          {/* <LifestyleSection /> */}
          {/* <SameMathNewLookSection /> */}
          <SBLOCSection />

          {isCompareActualInURL && <ComparePerformanceSection />}
          <PastPerformance />
          {/* <div className='d-flex justify-content-center align-items-center'>
            <FeatureSuggestionSection />
          </div> */}
          <FinanciallyFreeSection
            heading={FINANCIAL_SECTION.FINANCIALLY_FREE}
            subHeading={FINANCIAL_SECTION.FULL_POTENTIAL}
            paragraph1={FINANCIAL_SECTION.FREEDOM}
            paragraph2={FINANCIAL_SECTION.PEACE}
            paragraph3={FINANCIAL_SECTION.MISSION}
            paragraph4={FINANCIAL_SECTION.ABUNDANCE}
          />
        </div>
      </section>
    </>
  )
}

export {HomePage}
