import React from 'react'
import DraggableMaps from './MapOverlayTool'
import {useJsApiLoader} from '@react-google-maps/api'

const api_key = 'AIzaSyBmZ8qhoojM1kZGbPK9Cdhx4Pp8Kr3okuY'

const MapComponentWrapper = () => {
  const {isLoaded} = useJsApiLoader({
    googleMapsApiKey: api_key,
    libraries: ['places'],
  })

  if (!isLoaded) return <div>Loading maps...</div>

  return <DraggableMaps />
}


export default MapComponentWrapper
