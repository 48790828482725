import {SuggestedFeatureModal} from './_model'
export const SuggestedFeatures: SuggestedFeatureModal[] = [
  {
    id: 0,
    name: 'Mortgage Calculator',
    description: '',
  },
  {
    id: 1,
    name: 'Whole Life vs Stock',
    description: '',
  },
  {
    id: 2,
    name: 'Car Lease vs Buy',
    description: '',
  },
  {
    id: 3,
    name: 'Buy or Rent',
    description: '',
  },
  {
    id: 4,
    name: 'IBLOC',
    description: '',
  },
  {
    id: 5,
    name: 'SBLOC',
    description: '',
  },
  {
    id: 6,
    name: 'Cash or Finance',
    description: '',
  },
  {
    id: 7,
    name: 'How Long can I "Lose"',
    description: '',
  },
  {
    id: 8,
    name: 'Daily Habits Add Up',
    description: '',
  },
]
